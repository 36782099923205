// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Userlist_container__3hpYZ {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 20px; }\n\n.Userlist_userlistContainer__3gFt- {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1; }\n", ""]);
// Exports
exports.locals = {
	"container": "Userlist_container__3hpYZ",
	"userlistContainer": "Userlist_userlistContainer__3gFt-"
};
module.exports = exports;
