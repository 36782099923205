import cn from 'classnames'
import React, { FC } from 'react'

import styles from './VotingCard.module.scss'

export interface CardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean
  isActive?: boolean
  children?: React.ReactNode
  value: string | number
}

export const VotingCard: FC<CardProps> = props => {
  const { style, children, isDisabled, className, value, isActive, ...other } = props
  return (
    <button
      className={cn(className, styles.btnBasic, { [styles.active]: isActive })}
      style={style}
      disabled={isDisabled}
      {...other}
    >
      {value}
    </button>
  )
}
