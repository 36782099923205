import React, { FC, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { Container } from 'common/components/Container/Container'
import { Cards, Dock, Sidebar, Navigation, LoadingPage, BreakScreen } from 'components'
import { useParticipant, useRoom } from 'contexts/RoomProvider'

import styles from './Board.module.scss'

export const Board: FC = () => {
  const { participant } = useParticipant()
  const { room, setRoomID, loading } = useRoom()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (id) {
      setRoomID(id)
    }
  }, [id, setRoomID])

  return loading && !room ? (
    <LoadingPage />
  ) : participant ? (
    <div className={styles.app}>
      <BreakScreen />
      <Navigation />
      <Container>
        <Sidebar />
        <div className={styles.container}>
          <Cards />
          <Dock />
        </div>
      </Container>
    </div>
  ) : (
    <Redirect to={{ pathname: '/' }} />
  )
}
