import { gql } from '@apollo/client'

/* QUERYS AFTER REFACTORING */

/* Testing */

export const ADD_TEN_PARTICIPANTS = gql`
  mutation testingAddTenParticipants($roomID: String!) {
    testingAddTenParticipants(roomID: $roomID) {
      _id
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      voteComplete
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

// -- Subscriptions --
export const ROOM_UPDATED = gql`
  subscription roomUpdated {
    roomUpdated {
      breakEnd
      _id
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      voteComplete
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

// -- Querys --
export const GET_ROOM = gql`
  query room($roomID: String!) {
    room(roomID: $roomID) {
      _id
      breakEnd
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        needBreak
        name
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

// -- Mutations --
export const CREATE_ROOM_AND_PARTICIPANT = gql`
  mutation createRoomAndParticipant($participantObject: ParticipantInput!) {
    createRoomAndParticipant(participantObject: $participantObject) {
      room {
        _id
        voteComplete
        availablePresets {
          name
          cardValues {
            value
            index
          }
          presetIndex
        }
        selectedPreset
        preset {
          name
          cardValues {
            value
            index
          }
          presetIndex
        }
        participants {
          _id
          needBreak
          name
          job
          choiceValues {
            value
            index
          }
          active
          role
          didVote
          avatarIndex
        }
      }
      participantID
    }
  }
`

export const ADD_PARTICIPANT_TO_ROOM = gql`
  mutation addParticipantToRoom($participantObject: ParticipantInput!, $roomID: String!) {
    addParticipantToRoom(participantObject: $participantObject, roomID: $roomID) {
      room {
        _id
        voteComplete
        availablePresets {
          name
          cardValues {
            value
            index
          }
          presetIndex
        }
        selectedPreset
        preset {
          name
          cardValues {
            value
            index
          }
          presetIndex
        }
        participants {
          _id
          needBreak
          name
          job
          choiceValues {
            value
            index
          }
          active
          role
          didVote
          avatarIndex
        }
      }
      participantID
    }
  }
`

export const UPDATE_ROOM_VOTING_STATUS = gql`
  mutation updateRoomVotingStatus($roomID: String!, $newVotingStatus: Boolean!) {
    updateRoomVotingStatus(roomID: $roomID, newVotingStatus: $newVotingStatus) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const UPDATE_USER_PARTICIPATION = gql`
  mutation updateUserParticipation($userID: String!, $roomID: String!, $newState: Boolean!) {
    updateUserParticipation(userID: $userID, roomID: $roomID, newState: $newState) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        job
        choiceValues {
          value
          index
        }
        needBreak
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const UPDATE_USER_NEED_BREAK = gql`
  mutation updateUserNeedBreak($roomID: String!, $userID: String!, $needBreakState: Boolean!) {
    updateUserNeedBreak(roomID: $roomID, userID: $userID, needBreakState: $needBreakState) {
      _id
      breakEnd
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        job
        choiceValues {
          value
          index
        }
        needBreak
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const UPDATE_USER_CHOICE = gql`
  mutation updateUserChoice($roomID: String!, $userID: String!, $choiceValues: ChoiceValuesProps!) {
    updateUserChoice(roomID: $roomID, userID: $userID, choiceValues: $choiceValues) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        job
        choiceValues {
          value
          index
        }
        active
        needBreak
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const RESET_USER_CHOICE_ACTIVE_AND_DIDVOTE = gql`
  mutation resetUserChoiceActiveAndDidVote($roomID: String!, $userID: String!) {
    resetUserChoiceActiveAndDidVote(roomID: $roomID, userID: $userID) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const RESET_USER_ACTIVE_AND_DIDVOTE = gql`
  mutation resetUserActiveAndDidVote($roomID: String!, $userID: String!) {
    resetUserActiveAndDidVote(roomID: $roomID, userID: $userID) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const UPDATE_ROOM_PRESET = gql`
  mutation updateRoomPreset($roomID: String!, $presetIndex: Int!) {
    updateRoomPreset(roomID: $roomID, presetIndex: $presetIndex) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const REMOVE_USER_FROM_ROOM = gql`
  mutation removeUserFromRoom($roomID: String!, $userID: String!) {
    removeUserFromRoom(roomID: $roomID, userID: $userID) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const RESET_ALL_CHOICES = gql`
  mutation resetAllChoices($roomID: String!) {
    resetAllChoices(roomID: $roomID) {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

export const RESET_ALL_NEEDBREAK = gql`
  mutation resetAllNeedBreak($roomID: String!) {
    resetAllNeedBreak(roomID: $roomID) {
      _id
      breakEnd
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        name
        needBreak
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
  }
`

/* ------------------------------------------------------------------------------------------------------------------------------------- */

// /* QUERYS BEFORE REFACTORING */
// -- Subscriptions --
// export const UPDATED_ROOM_PARTICIPANTS = gql`
//   subscription participantsUpdated {
//     participantsUpdated {
//       participants {
//         choice
//         name
//         active
//         index
//       }
//     }
//   }
// `

// export const UPDATED_ROOM_PRESET = gql`
//   subscription presetUpdated {
//     presetUpdated {
//       _id
//       preset {
//         _id
//         props {
//           cardValues
//         }
//       }
//     }
//   }
// `

// export const UPDATED_USER_CHOICE = gql`
//   subscription userChoiceUpdated {
//     userChoiceUpdated
//   }
// `

// export const UPDATED_USER_PARTICIPATION = gql`
//   subscription userParticipationUpdated {
//     userParticipationUpdated
//   }
// `

// export const UPDATED_VOTINGSTATUS = gql`
//   subscription votingStatusUpdated {
//     votingStatusUpdated
//   }
// `

// // -- Querys --
// export const GET_USER_CHOICE = gql`
//   query room($roomID: String!, $userID: String!) {
//     getUserChoice(roomID: $roomID, userID: $userID)
//   }
// `

// export const GET_ROOMS_VOTINGSTATUS = gql`
//   query getRoomVotingStatus($roomID: String!) {
//     getRoomVotingStatus(roomID: $roomID)
//   }
// `

// export const GET_USER_ROLE = gql`
//   query getUserRole($roomID: String!, $userID: String!) {
//     getUserRole(roomID: $roomID, userID: $userID)
//   }
// `

// export const GET_ACTIVE_STATE = gql`
//   query getActiveState($roomID: String!, $userID: String!) {
//     getActiveState(roomID: $roomID, userID: $userID)
//   }
// `

// export const GET_USER_BY_ID = gql`
//   query getUserById($userID: String!) {
//     getUserById(userID: $userID) {
//       name
//       title
//       _id
//     }
//   }
// `

// export const GET_ROOM_BY_ID = gql`
//   query getRoomById($roomID: String!) {
//     getRoomById(roomID: $roomID) {
//       preset {
//         props {
//           _id
//           name
//           cardValues
//         }
//       }
//     }
//   }
// `

// export const GET_PRESET_BY_ID = gql`
//   query getPresetById($presetID: String!) {
//     getPresetById(presetID: $presetID) {
//       _id
//       props {
//         _id
//         name
//         cardValues
//       }
//     }
//   }
// `

// export const GET_USER_INDEX = gql`
//   query getUserIndex($roomID: String!, $userID: String!) {
//     getUserIndex(roomID: $roomID, userID: $userID)
//   }
// `

// export const GET_ALL_PRESETS = gql`
//   query getAllPresets {
//     getAllPresets {
//       _id
//       props {
//         _id
//         name
//         cardValues
//       }
//     }
//   }
// `

// export const GET_ROOM_PRESET = gql`
//   query getRoomPreset($roomID: String!) {
//     getRoomPreset(roomID: $roomID) {
//       _id
//       preset {
//         props {
//           _id
//           name
//           cardValues
//         }
//       }
//     }
//   }
// `

// export const GET_ALL_PARTICIPANTS = gql`
//   query getAllParticipants($roomID: String!) {
//     getAllParticipants(roomID: $roomID) {
//       participants {
//         userID
//         name
//         title
//         didVote
//         active
//         choice
//         index
//       }
//     }
//   }
// `

// -- Mutations --
// export const CREATE_USER = gql`
//   mutation createUser($userObj: UserInput!) {
//     createUser(userObj: $userObj) {
//       name
//       title
//       _id
//     }
//   }
// `

// export const CREATEROOM_AND_ADDUSER = gql`
//   mutation createRoomAndAddUser($userID: String!) {
//     createRoomAndAddUser(userID: $userID) {
//       _id
//       participants {
//         role
//       }
//     }
//   }
// `

// export const UPDATE_ROOM_VOTING_STATUS = gql`
//   mutation updateRoomVotingStatus($roomID: String!, $newStatus: Boolean!) {
//     updateRoomVotingStatus(roomID: $roomID, newStatus: $newStatus)
//   }
// `

// export const UPDATE_USERPARTICIPATION = gql`
//   mutation updateUserParticipation($userID: String!, $roomID: String!, $newState: Boolean!) {
//     updateUserParticipation(userID: $userID, roomID: $roomID, newState: $newState)
//   }
// `

// export const RESET_ALL_CHOICES = gql`
//   mutation resetAllChoices($roomID: String!) {
//     resetAllChoices(roomID: $roomID) {
//       _id
//     }
//   }
// `

// export const UPDATE_USERCHOICE = gql`
//   mutation updateUserChoice($userID: String!, $roomID: String!, $choice: String!) {
//     updateUserChoice(userID: $userID, roomID: $roomID, choice: $choice)
//   }
// `

// export const UPDATE_USER_DIDVOTE = gql`
//   mutation updateUserDidVote($roomID: String!, $userID: String!, $boolValue: Boolean!) {
//     updateUserDidVote(roomID: $roomID, userID: $userID, boolValue: $boolValue)
//   }
// `

// export const ADD_USER_TO_ROOM = gql`
//   mutation addUserToRoom($userID: String!, $roomID: String!) {
//     addUserToRoom(userID: $userID, roomID: $roomID) {
//       _id
//       participants {
//         name
//         choice
//         role
//         index
//       }
//     }
//   }
// `

// export const UPDATE_ROOM_PRESET = gql`
//   mutation updateRoomPreset($roomID: String!, $presetID: String, $customPreset: [String!]) {
//     updateRoomPreset(roomID: $roomID, presetID: $presetID, customPreset: $customPreset) {
//       _id
//       preset {
//         _id
//         props {
//           _id
//           name
//           cardValues
//         }
//       }
//     }
//   }
// `

// export const REMOVE_USER_FROM_ROOM = gql`
//   mutation removeUserFromRoom($roomID: String!, $userID: String!) {
//     removeUserFromRoom(roomID: $roomID, userID: $userID) {
//       _id
//     }
//   }
// `
