import React, { FC, useEffect, useState } from 'react'
import Confetti from 'react-confetti'

import styles from 'components/Cards/Cards.module.scss'
import { ParticipantCard } from 'components/ParticipantCard/ParticipantCard'
import { useBreakpoints } from 'contexts/BreakpointProvider'
import { useRoom } from 'contexts/RoomProvider'
import { ParticipantProps } from 'graphql/interfaces/schema'
import { evaluateLowestAndHighest, IMaximaType } from 'utils/evaluateLowestAndHighest'

export const Cards: FC = () => {
  const [areVotesEqual, setAreVotesEqual] = useState<boolean>(false)
  const [confettiRecycleState, setConfettiRecycleState] = useState<boolean>(true)
  const [maximaState, setMaximaState] = useState<IMaximaType | []>()
  const { room } = useRoom()
  const { isScreenXS, isScreenSM } = useBreakpoints()
  const offSet = -168
  const recycleTime = 2000
  const activeParticipantsInRoom = room?.participants.filter((element: ParticipantProps) => element.active)

  useEffect(() => {
    const activeParticipantsWithChoice = room?.participants.filter(
      (element: ParticipantProps) => element.active && !!element.choiceValues.value
    )

    if (activeParticipantsWithChoice) {
      const allEqual =
        activeParticipantsWithChoice.length > 1 &&
        activeParticipantsWithChoice?.every(
          (element: ParticipantProps) => element.choiceValues.value === activeParticipantsWithChoice[0].choiceValues.value
        )

      setAreVotesEqual(allEqual)
    }
  }, [activeParticipantsInRoom?.length, room])

  useEffect(() => {
    if (room?.voteComplete) {
      room &&
        setMaximaState(
          evaluateLowestAndHighest(
            room?.participants.filter(p => p.choiceValues.index !== 0).map(element => element.choiceValues.index)
          )
        )
      setTimeout(() => setConfettiRecycleState(false), recycleTime)
    } else if (!room?.voteComplete) {
      setMaximaState([])
    }
  }, [room])

  return (
    <div className={styles.cardsContainer}>
      {room?.voteComplete && areVotesEqual && !!activeParticipantsInRoom?.length && <Confetti recycle={confettiRecycleState} />}

      {activeParticipantsInRoom?.map((participant: ParticipantProps, mapIndex: number) => {
        const { name, choiceValues, didVote, avatarIndex, needBreak } = participant

        return (
          <ParticipantCard
            voted={didVote}
            isRevealed={!!room?.voteComplete}
            size={isScreenXS.exact ? 'mobile' : isScreenSM.exact ? 'tablet' : 'desktop'}
            style={{
              backgroundPositionX: offSet * mapIndex,
            }}
            key={mapIndex}
            needBreak={needBreak}
            isLowestCard={room?.voteComplete && choiceValues.index === maximaState?.[0]}
            isHighestCard={room?.voteComplete && choiceValues.index === maximaState?.[1]}
            slideUp={(room?.voteComplete && maximaState?.[0] === choiceValues.index) || maximaState?.[1] === choiceValues.index}
            value={choiceValues.value || ''}
            avatarIndex={avatarIndex}
            background
            user={{ name }}
            animate
          />
        )
      })}
    </div>
  )
}
