import cn from 'classnames'
import { motion } from 'framer-motion'
import React, { useState, FC, useEffect } from 'react'
import { AiOutlineUser as UserIcon } from 'react-icons/ai'
import { RiDoorLockLine as RoomIcon } from 'react-icons/ri'

import { Input, Button } from 'common/components'
import { Tabmenu, Tab } from 'components'
import { useRoom } from 'contexts/RoomProvider'
import { useAddParticipantToRoomMutation, useCreateRoomAndParticipantMutation } from 'graphql/interfaces/schema'
import { LoginInputProps } from 'pages/Login/Interfaces/LoginProps'

import styles from './Login.module.scss'

export const Login: FC = () => {
  const { gotoRoom, roomID, clearAppState } = useRoom()
  const [inputValues, setinputValues] = useState<LoginInputProps>({
    name: '',
    id: '',
  })

  const { name, id } = inputValues

  const [createRoomAndParticipant] = useCreateRoomAndParticipantMutation()
  const [addParticipantToRoom] = useAddParticipantToRoomMutation()

  const createUserAndCreateRoom = (): void => {
    if (name) {
      createRoomAndParticipant({ variables: { participantObject: { name } } })
        .then(mutatedObject => {
          const roomObject = mutatedObject.data?.createRoomAndParticipant
          if (roomObject) {
            gotoRoom(roomObject.room._id, roomObject.participantID)
          }
        })
        .catch(clearAppState)
    }
  }

  const createUserAndAddToRoom = (): void => {
    if (name) {
      addParticipantToRoom({ variables: { participantObject: { name }, roomID: id } })
        .then(mutatedObject => {
          const roomObject = mutatedObject.data?.addParticipantToRoom
          if (roomObject) {
            gotoRoom(roomObject.room._id, roomObject.participantID)
          }
        })
        .catch(clearAppState)
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, variant: boolean): void => {
    if (event.key === 'Enter') {
      if (variant) createUserAndCreateRoom()
      else createUserAndAddToRoom()
    }
  }

  useEffect(() => {
    if (roomID) {
      setinputValues({ ...inputValues, id: roomID })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomID])

  const handleOnChange = (state: string) => (event: React.ChangeEvent<HTMLInputElement>): void =>
    setinputValues({ ...inputValues, [state]: event.target.value })

  const UserNameInput = (variant: boolean): JSX.Element => (
    <Input
      type="text"
      name="username"
      value={name}
      onChange={handleOnChange('name')}
      className={cn(styles.inputField)}
      iconLeft={<UserIcon />}
      onKeyPress={event => onKeyPress(event, variant)}
      placeholder="Name *"
      autoComplete="off"
      required
    />
  )

  const RoomIdInput = (variant: boolean): JSX.Element => (
    <Input
      type="text"
      name="roomid"
      maxLength={24}
      pattern="[^a-z]"
      value={id}
      onChange={handleOnChange('id')}
      placeholder="Room ID *"
      onKeyPress={event => onKeyPress(event, variant)}
      className={cn(styles.inputField)}
      iconLeft={<RoomIcon />}
    />
  )

  return (
    <div className={styles.loginContainer}>
      <section className={styles.firstSection}>
        <div className={styles.background} />
        <motion.div className={styles.headline} animate={{ opacity: [0, 1], y: [0, 10] }} transition={{ delay: 0.2 }}>
          <div className={styles.mainHeading}>Scrumdenken.de</div>
          <div className={styles.subheading}>Planning Poker</div>
        </motion.div>
      </section>
      <span className={styles.middleCircle}>
        <div className={styles.logo} />
      </span>

      <motion.section className={styles.secondSection} animate={{ opacity: [0, 1], y: [10, 0] }} transition={{ delay: 0.6 }}>
        <span style={{ visibility: 'hidden' }} />
        <div className={styles.inputContainer}>
          <Tabmenu
            classes={{ tabmenu: styles.tabmenu, tab: styles.tab, tabActive: styles.tabActive }}
            activeTabIndex={roomID ? 1 : 0}
          >
            <Tab title="Create Room">
              <div className={styles.loginForm}>
                {UserNameInput(true)}

                <Button name="createroom" disabled={!name} onClick={createUserAndCreateRoom} form="login" className="fullwidth">
                  Create!
                </Button>
              </div>
            </Tab>
            <Tab title="Join Room">
              <div className={styles.loginForm}>
                {RoomIdInput(false)}
                {UserNameInput(false)}

                <Button onClick={createUserAndAddToRoom} name="joinroom" className="fullwidth" disabled={!(id && name)}>
                  Join!
                </Button>
              </div>
            </Tab>
          </Tabmenu>
        </div>
        <div className={styles.versionTag}>{process.env.REACT_APP_VERSION}</div>
      </motion.section>
    </div>
  )
}
