// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Userblock_userblockContainer__3ju9M {\n  display: flex;\n  flex-basis: 30%;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  font-family: \"Montserrat\", sans-serif; }\n  .Userblock_userblockContainer__3ju9M .Userblock_userblockAvatarSize__ZgPTQ {\n    width: 120px;\n    height: 120px; }\n    @media only screen and (max-width: 620px) {\n      .Userblock_userblockContainer__3ju9M .Userblock_userblockAvatarSize__ZgPTQ {\n        width: 60px;\n        height: 60px; } }\n  .Userblock_userblockContainer__3ju9M .Userblock_userblockUserInfo__1QqTx {\n    z-index: 10;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin-left: 1rem;\n    color: #fff; }\n    .Userblock_userblockContainer__3ju9M .Userblock_userblockUserInfo__1QqTx .Userblock_name__22pc9 {\n      font-weight: 300;\n      font-size: 1rem; }\n    .Userblock_userblockContainer__3ju9M .Userblock_userblockUserInfo__1QqTx .Userblock_jobTitle__35jtv {\n      font-weight: 300;\n      font-size: 0.875rem; }\n", ""]);
// Exports
exports.locals = {
	"userblockContainer": "Userblock_userblockContainer__3ju9M",
	"userblockAvatarSize": "Userblock_userblockAvatarSize__ZgPTQ",
	"userblockUserInfo": "Userblock_userblockUserInfo__1QqTx",
	"name": "Userblock_name__22pc9",
	"jobTitle": "Userblock_jobTitle__35jtv"
};
module.exports = exports;
