import React, { FC } from 'react'

import styles from 'components/Userlist/Userlist.module.scss'
import { useRoom } from 'contexts/RoomProvider'

import { UserTable } from './components/UserTable'

export const Userlist: FC = () => {
  const { room } = useRoom()
  const participants = room?.participants.filter(element => element.active)
  const spectators = room?.participants.filter(element => !element.active)

  const isListNotEmpty = (array: Array<unknown> | undefined): boolean => !!array && array.length > 0

  const UserList = (): JSX.Element => (
    <div className={styles.container}>
      {isListNotEmpty(participants) && <UserTable headline="Participants" users={participants!} />}
      {isListNotEmpty(spectators) && <UserTable headline="Spectators" users={spectators!} />}
    </div>
  )

  return <div className={styles.userlistContainer}>{UserList()}</div>
}
