/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import cn from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { AiOutlineMenu as HamburgerMenuIcon } from 'react-icons/ai'
import { FiLogOut as LogoutIcon } from 'react-icons/fi'
import { MdContentCopy as CopyIcon, MdCheck as CheckIcon } from 'react-icons/md'
import { VscMegaphone as FeedbackIcon, VscChromeClose as CloseIcon } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'

import { Button } from 'common/components'
import { useAppState } from 'contexts/AppProvider'
import { useBreakpoints } from 'contexts/BreakpointProvider'
import { useParticipant, useRoom } from 'contexts/RoomProvider'
import { useRemoveUserFromRoomMutation } from 'graphql/interfaces/schema'
import { ReactComponent as ScrumdenkenLogo } from 'static/svg/logo-without-subtext-gradient.svg'
import { ReactComponent as PlanningPokerLogo } from 'static/svg/poker.svg'
import { copyToClipboard } from 'utils/copyToClipboard'

import styles from './Navigation.module.scss'

export const Navigation: FC = () => {
  const { isScreenSM } = useBreakpoints()
  const BUTTON_COPY_TEXT = `Share link`
  const BUTTON_COPIED_TEXT = 'Copied!'
  const BUTTON_TEXT_DURATION = 2000

  const [copiedState, setCopiedState] = useState<boolean>(false)

  const [removeUserFromRoom] = useRemoveUserFromRoomMutation()
  const history = useHistory()
  const { room, setRoomID, clearAppState } = useRoom()
  const { participant } = useParticipant()
  const { toggleSidebar, isSidebarCollapsed } = useAppState()

  const handleLogout = async (): Promise<void> => {
    if (participant && room) {
      localStorage.clear()
      setRoomID('')
      await removeUserFromRoom({ variables: { roomID: room?._id, userID: participant._id } })
        .then(() => history.push('/'))
        .catch(clearAppState)
    }
  }

  const handleShareClick = (): void => {
    copyToClipboard(`${window.location.href}`) && setCopiedState(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopiedState(false)
    }, BUTTON_TEXT_DURATION)
    return () => clearTimeout(timer)
  }, [handleShareClick])

  return (
    <div className={styles.navigationContainer}>
      <div className={styles.navLeft}>
        <div className={styles.hamburgerMenuContainer}>
          {isSidebarCollapsed ? <HamburgerMenuIcon onClick={toggleSidebar} /> : <CloseIcon onClick={toggleSidebar} />}
        </div>
        {isScreenSM.up && (
          <>
            <a href="https://scrumdenken.de/" target="_blank" rel="noreferrer" className={styles.link}>
              <ScrumdenkenLogo title="Go to scrumdenken.de" width={35} height={35} />
            </a>

            <div className={styles.navHeadline}>SCRUMDENKEN</div>
          </>
        )}
      </div>
      <div className={styles.navCenter}>
        <PlanningPokerLogo />
        {isScreenSM.up && <div className={styles.logoHeadline}>PLANNING POKER</div>}
      </div>
      <div className={styles.navRight}>
        <div className={styles.actionButtons}>
          <Button
            className={cn(styles.button, styles.copyButton, { [styles.stateCopied]: copiedState })}
            iconRight={copiedState ? <CheckIcon /> : <CopyIcon />}
            onClick={handleShareClick}
            title="Copy link to clipboard"
          >
            {isScreenSM.up && <div className={styles.copyButtonText}>{copiedState ? BUTTON_COPIED_TEXT : BUTTON_COPY_TEXT}</div>}
          </Button>
          {isScreenSM.up && (
            <a
              href="https://scrumdenken.de/kontakt#content-bottom-row"
              className={styles.feedbackLink}
              target="_blank"
              rel="noreferrer"
            >
              <Button className={cn(styles.button, styles.feedbackButton)} title="Give us Feedback">
                <FeedbackIcon />
              </Button>
            </a>
          )}
          <Button className={cn(styles.button, styles.logoutButton)} onClick={handleLogout} title="Logout">
            <LogoutIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
