// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable color-named */\n/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Separator_separatorContainer__p8n9a {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  width: 100%; }\n\n.Separator_seperator__lH0Wd {\n  width: 90%;\n  height: 0.0625rem;\n  border-top: 0.0625rem solid #ccc; }\n\n.Separator_label__318P8 {\n  position: absolute;\n  margin-top: -9px;\n  padding: 0 20px;\n  color: grey;\n  font-family: \"Montserrat\", sans-serif;\n  background-color: #fafafa; }\n", ""]);
// Exports
exports.locals = {
	"separatorContainer": "Separator_separatorContainer__p8n9a",
	"seperator": "Separator_seperator__lH0Wd",
	"label": "Separator_label__318P8"
};
module.exports = exports;
