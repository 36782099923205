// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.UserTable_container__1sEjf {\n  padding: 10px 20px; }\n\n.UserTable_heading__3qH-U {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: -moz-fit-content;\n  height: fit-content;\n  margin: 30px 0 10px;\n  font-weight: 400;\n  font-size: 13pt;\n  font-family: \"Montserrat\", sans-serif;\n  text-align: center; }\n\n.UserTable_tableRow__sZ_Hn {\n  display: flex; }\n\n.UserTable_name__3cL_z {\n  display: flex;\n  flex-basis: 70%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n.UserTable_table__2Csve {\n  width: 100%; }\n  .UserTable_table__2Csve td {\n    font-weight: 100;\n    font-size: 15px; }\n\n.UserTable_statusIcon__2fc3F {\n  display: flex;\n  flex-basis: 10%;\n  align-items: center;\n  justify-content: center; }\n", ""]);
// Exports
exports.locals = {
	"container": "UserTable_container__1sEjf",
	"heading": "UserTable_heading__3qH-U",
	"tableRow": "UserTable_tableRow__sZ_Hn",
	"name": "UserTable_name__3cL_z",
	"table": "UserTable_table__2Csve",
	"statusIcon": "UserTable_statusIcon__2fc3F"
};
module.exports = exports;
