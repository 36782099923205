/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: Date;
};


export type CardValuesProps = {
  __typename?: 'CardValuesProps';
  value: Scalars['String'];
  index: Scalars['Int'];
};

export type ChoiceValuesProps = {
  value: Scalars['String'];
  index: Scalars['Int'];
};

export type PresetProps = {
  __typename?: 'PresetProps';
  name: Scalars['String'];
  cardValues: Array<CardValuesProps>;
  presetIndex: Scalars['Int'];
};

export type Room = {
  __typename?: 'Room';
  _id: Scalars['String'];
  participants: Array<ParticipantProps>;
  breakEnd?: Maybe<Scalars['String']>;
  preset: PresetProps;
  voteComplete: Scalars['Boolean'];
  selectedPreset: Scalars['Int'];
  availablePresets: Array<PresetProps>;
};

export type ParticipantProps = {
  __typename?: 'ParticipantProps';
  _id: Scalars['String'];
  name: Scalars['String'];
  job?: Maybe<Scalars['String']>;
  choiceValues: CardValuesProps;
  needBreak: Scalars['Boolean'];
  role: Scalars['String'];
  avatarIndex: Scalars['Int'];
  didVote: Scalars['Boolean'];
  active: Scalars['Boolean'];
};

export type ParticipantInput = {
  name: Scalars['String'];
  job?: Maybe<Scalars['String']>;
};

export type NewParticipantInRoom = {
  __typename?: 'NewParticipantInRoom';
  room: Room;
  participantID: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  room: Room;
};


export type QueryRoomArgs = {
  roomID: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createRoomAndParticipant: NewParticipantInRoom;
  addParticipantToRoom: NewParticipantInRoom;
  updateRoomVotingStatus: Room;
  updateUserParticipation: Room;
  updateUserChoice: Room;
  updateRoomPreset: Room;
  updateUserDidVote: Room;
  updateUserNeedBreak: Room;
  resetAllChoices: Room;
  resetAllNeedBreak: Room;
  resetUserActiveAndDidVote: Room;
  resetUserChoiceActiveAndDidVote: Room;
  removeUserFromRoom: Room;
  testingAddTenParticipants: Room;
};


export type MutationCreateRoomAndParticipantArgs = {
  participantObject: ParticipantInput;
};


export type MutationAddParticipantToRoomArgs = {
  participantObject: ParticipantInput;
  roomID: Scalars['String'];
};


export type MutationUpdateRoomVotingStatusArgs = {
  roomID: Scalars['String'];
  newVotingStatus: Scalars['Boolean'];
};


export type MutationUpdateUserParticipationArgs = {
  roomID: Scalars['String'];
  userID: Scalars['String'];
  newState: Scalars['Boolean'];
};


export type MutationUpdateUserChoiceArgs = {
  roomID: Scalars['String'];
  userID: Scalars['String'];
  choiceValues: ChoiceValuesProps;
};


export type MutationUpdateRoomPresetArgs = {
  roomID: Scalars['String'];
  presetIndex: Scalars['Int'];
};


export type MutationUpdateUserDidVoteArgs = {
  roomID: Scalars['String'];
  userID: Scalars['String'];
  newDidVoteState: Scalars['Boolean'];
};


export type MutationUpdateUserNeedBreakArgs = {
  roomID: Scalars['String'];
  userID: Scalars['String'];
  needBreakState: Scalars['Boolean'];
};


export type MutationResetAllChoicesArgs = {
  roomID: Scalars['String'];
};


export type MutationResetAllNeedBreakArgs = {
  roomID: Scalars['String'];
};


export type MutationResetUserActiveAndDidVoteArgs = {
  roomID: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationResetUserChoiceActiveAndDidVoteArgs = {
  roomID: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationRemoveUserFromRoomArgs = {
  roomID: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationTestingAddTenParticipantsArgs = {
  roomID: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  roomUpdated: Room;
};

export type TestingAddTenParticipantsMutationVariables = Exact<{
  roomID: Scalars['String'];
}>;


export type TestingAddTenParticipantsMutation = (
  { __typename?: 'Mutation' }
  & { testingAddTenParticipants: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type RoomUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RoomUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { roomUpdated: (
    { __typename?: 'Room' }
    & Pick<Room, 'breakEnd' | '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type RoomQueryVariables = Exact<{
  roomID: Scalars['String'];
}>;


export type RoomQuery = (
  { __typename?: 'Query' }
  & { room: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'breakEnd' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'needBreak' | 'name' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type CreateRoomAndParticipantMutationVariables = Exact<{
  participantObject: ParticipantInput;
}>;


export type CreateRoomAndParticipantMutation = (
  { __typename?: 'Mutation' }
  & { createRoomAndParticipant: (
    { __typename?: 'NewParticipantInRoom' }
    & Pick<NewParticipantInRoom, 'participantID'>
    & { room: (
      { __typename?: 'Room' }
      & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
      & { availablePresets: Array<(
        { __typename?: 'PresetProps' }
        & Pick<PresetProps, 'name' | 'presetIndex'>
        & { cardValues: Array<(
          { __typename?: 'CardValuesProps' }
          & Pick<CardValuesProps, 'value' | 'index'>
        )> }
      )>, preset: (
        { __typename?: 'PresetProps' }
        & Pick<PresetProps, 'name' | 'presetIndex'>
        & { cardValues: Array<(
          { __typename?: 'CardValuesProps' }
          & Pick<CardValuesProps, 'value' | 'index'>
        )> }
      ), participants: Array<(
        { __typename?: 'ParticipantProps' }
        & Pick<ParticipantProps, '_id' | 'needBreak' | 'name' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
        & { choiceValues: (
          { __typename?: 'CardValuesProps' }
          & Pick<CardValuesProps, 'value' | 'index'>
        ) }
      )> }
    ) }
  ) }
);

export type AddParticipantToRoomMutationVariables = Exact<{
  participantObject: ParticipantInput;
  roomID: Scalars['String'];
}>;


export type AddParticipantToRoomMutation = (
  { __typename?: 'Mutation' }
  & { addParticipantToRoom: (
    { __typename?: 'NewParticipantInRoom' }
    & Pick<NewParticipantInRoom, 'participantID'>
    & { room: (
      { __typename?: 'Room' }
      & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
      & { availablePresets: Array<(
        { __typename?: 'PresetProps' }
        & Pick<PresetProps, 'name' | 'presetIndex'>
        & { cardValues: Array<(
          { __typename?: 'CardValuesProps' }
          & Pick<CardValuesProps, 'value' | 'index'>
        )> }
      )>, preset: (
        { __typename?: 'PresetProps' }
        & Pick<PresetProps, 'name' | 'presetIndex'>
        & { cardValues: Array<(
          { __typename?: 'CardValuesProps' }
          & Pick<CardValuesProps, 'value' | 'index'>
        )> }
      ), participants: Array<(
        { __typename?: 'ParticipantProps' }
        & Pick<ParticipantProps, '_id' | 'needBreak' | 'name' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
        & { choiceValues: (
          { __typename?: 'CardValuesProps' }
          & Pick<CardValuesProps, 'value' | 'index'>
        ) }
      )> }
    ) }
  ) }
);

export type UpdateRoomVotingStatusMutationVariables = Exact<{
  roomID: Scalars['String'];
  newVotingStatus: Scalars['Boolean'];
}>;


export type UpdateRoomVotingStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateRoomVotingStatus: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type UpdateUserParticipationMutationVariables = Exact<{
  userID: Scalars['String'];
  roomID: Scalars['String'];
  newState: Scalars['Boolean'];
}>;


export type UpdateUserParticipationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserParticipation: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'job' | 'needBreak' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type UpdateUserNeedBreakMutationVariables = Exact<{
  roomID: Scalars['String'];
  userID: Scalars['String'];
  needBreakState: Scalars['Boolean'];
}>;


export type UpdateUserNeedBreakMutation = (
  { __typename?: 'Mutation' }
  & { updateUserNeedBreak: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'breakEnd' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'job' | 'needBreak' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type UpdateUserChoiceMutationVariables = Exact<{
  roomID: Scalars['String'];
  userID: Scalars['String'];
  choiceValues: ChoiceValuesProps;
}>;


export type UpdateUserChoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateUserChoice: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'job' | 'active' | 'needBreak' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type ResetUserChoiceActiveAndDidVoteMutationVariables = Exact<{
  roomID: Scalars['String'];
  userID: Scalars['String'];
}>;


export type ResetUserChoiceActiveAndDidVoteMutation = (
  { __typename?: 'Mutation' }
  & { resetUserChoiceActiveAndDidVote: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type ResetUserActiveAndDidVoteMutationVariables = Exact<{
  roomID: Scalars['String'];
  userID: Scalars['String'];
}>;


export type ResetUserActiveAndDidVoteMutation = (
  { __typename?: 'Mutation' }
  & { resetUserActiveAndDidVote: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type UpdateRoomPresetMutationVariables = Exact<{
  roomID: Scalars['String'];
  presetIndex: Scalars['Int'];
}>;


export type UpdateRoomPresetMutation = (
  { __typename?: 'Mutation' }
  & { updateRoomPreset: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type RemoveUserFromRoomMutationVariables = Exact<{
  roomID: Scalars['String'];
  userID: Scalars['String'];
}>;


export type RemoveUserFromRoomMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromRoom: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type ResetAllChoicesMutationVariables = Exact<{
  roomID: Scalars['String'];
}>;


export type ResetAllChoicesMutation = (
  { __typename?: 'Mutation' }
  & { resetAllChoices: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);

export type ResetAllNeedBreakMutationVariables = Exact<{
  roomID: Scalars['String'];
}>;


export type ResetAllNeedBreakMutation = (
  { __typename?: 'Mutation' }
  & { resetAllNeedBreak: (
    { __typename?: 'Room' }
    & Pick<Room, '_id' | 'breakEnd' | 'voteComplete' | 'selectedPreset'>
    & { availablePresets: Array<(
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    )>, preset: (
      { __typename?: 'PresetProps' }
      & Pick<PresetProps, 'name' | 'presetIndex'>
      & { cardValues: Array<(
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      )> }
    ), participants: Array<(
      { __typename?: 'ParticipantProps' }
      & Pick<ParticipantProps, '_id' | 'name' | 'needBreak' | 'job' | 'active' | 'role' | 'didVote' | 'avatarIndex'>
      & { choiceValues: (
        { __typename?: 'CardValuesProps' }
        & Pick<CardValuesProps, 'value' | 'index'>
      ) }
    )> }
  ) }
);


export const TestingAddTenParticipantsDocument = gql`
    mutation testingAddTenParticipants($roomID: String!) {
  testingAddTenParticipants(roomID: $roomID) {
    _id
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    voteComplete
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type TestingAddTenParticipantsMutationFn = Apollo.MutationFunction<TestingAddTenParticipantsMutation, TestingAddTenParticipantsMutationVariables>;

/**
 * __useTestingAddTenParticipantsMutation__
 *
 * To run a mutation, you first call `useTestingAddTenParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestingAddTenParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testingAddTenParticipantsMutation, { data, loading, error }] = useTestingAddTenParticipantsMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useTestingAddTenParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<TestingAddTenParticipantsMutation, TestingAddTenParticipantsMutationVariables>) {
        return Apollo.useMutation<TestingAddTenParticipantsMutation, TestingAddTenParticipantsMutationVariables>(TestingAddTenParticipantsDocument, baseOptions);
      }
export type TestingAddTenParticipantsMutationHookResult = ReturnType<typeof useTestingAddTenParticipantsMutation>;
export type TestingAddTenParticipantsMutationResult = Apollo.MutationResult<TestingAddTenParticipantsMutation>;
export type TestingAddTenParticipantsMutationOptions = Apollo.BaseMutationOptions<TestingAddTenParticipantsMutation, TestingAddTenParticipantsMutationVariables>;
export const RoomUpdatedDocument = gql`
    subscription roomUpdated {
  roomUpdated {
    breakEnd
    _id
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    voteComplete
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;

/**
 * __useRoomUpdatedSubscription__
 *
 * To run a query within a React component, call `useRoomUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRoomUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RoomUpdatedSubscription, RoomUpdatedSubscriptionVariables>) {
        return Apollo.useSubscription<RoomUpdatedSubscription, RoomUpdatedSubscriptionVariables>(RoomUpdatedDocument, baseOptions);
      }
export type RoomUpdatedSubscriptionHookResult = ReturnType<typeof useRoomUpdatedSubscription>;
export type RoomUpdatedSubscriptionResult = Apollo.SubscriptionResult<RoomUpdatedSubscription>;
export const RoomDocument = gql`
    query room($roomID: String!) {
  room(roomID: $roomID) {
    _id
    breakEnd
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      needBreak
      name
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;

/**
 * __useRoomQuery__
 *
 * To run a query within a React component, call `useRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomQuery({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useRoomQuery(baseOptions: Apollo.QueryHookOptions<RoomQuery, RoomQueryVariables>) {
        return Apollo.useQuery<RoomQuery, RoomQueryVariables>(RoomDocument, baseOptions);
      }
export function useRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomQuery, RoomQueryVariables>) {
          return Apollo.useLazyQuery<RoomQuery, RoomQueryVariables>(RoomDocument, baseOptions);
        }
export type RoomQueryHookResult = ReturnType<typeof useRoomQuery>;
export type RoomLazyQueryHookResult = ReturnType<typeof useRoomLazyQuery>;
export type RoomQueryResult = Apollo.QueryResult<RoomQuery, RoomQueryVariables>;
export const CreateRoomAndParticipantDocument = gql`
    mutation createRoomAndParticipant($participantObject: ParticipantInput!) {
  createRoomAndParticipant(participantObject: $participantObject) {
    room {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        needBreak
        name
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
    participantID
  }
}
    `;
export type CreateRoomAndParticipantMutationFn = Apollo.MutationFunction<CreateRoomAndParticipantMutation, CreateRoomAndParticipantMutationVariables>;

/**
 * __useCreateRoomAndParticipantMutation__
 *
 * To run a mutation, you first call `useCreateRoomAndParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomAndParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomAndParticipantMutation, { data, loading, error }] = useCreateRoomAndParticipantMutation({
 *   variables: {
 *      participantObject: // value for 'participantObject'
 *   },
 * });
 */
export function useCreateRoomAndParticipantMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoomAndParticipantMutation, CreateRoomAndParticipantMutationVariables>) {
        return Apollo.useMutation<CreateRoomAndParticipantMutation, CreateRoomAndParticipantMutationVariables>(CreateRoomAndParticipantDocument, baseOptions);
      }
export type CreateRoomAndParticipantMutationHookResult = ReturnType<typeof useCreateRoomAndParticipantMutation>;
export type CreateRoomAndParticipantMutationResult = Apollo.MutationResult<CreateRoomAndParticipantMutation>;
export type CreateRoomAndParticipantMutationOptions = Apollo.BaseMutationOptions<CreateRoomAndParticipantMutation, CreateRoomAndParticipantMutationVariables>;
export const AddParticipantToRoomDocument = gql`
    mutation addParticipantToRoom($participantObject: ParticipantInput!, $roomID: String!) {
  addParticipantToRoom(participantObject: $participantObject, roomID: $roomID) {
    room {
      _id
      voteComplete
      availablePresets {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      selectedPreset
      preset {
        name
        cardValues {
          value
          index
        }
        presetIndex
      }
      participants {
        _id
        needBreak
        name
        job
        choiceValues {
          value
          index
        }
        active
        role
        didVote
        avatarIndex
      }
    }
    participantID
  }
}
    `;
export type AddParticipantToRoomMutationFn = Apollo.MutationFunction<AddParticipantToRoomMutation, AddParticipantToRoomMutationVariables>;

/**
 * __useAddParticipantToRoomMutation__
 *
 * To run a mutation, you first call `useAddParticipantToRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantToRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantToRoomMutation, { data, loading, error }] = useAddParticipantToRoomMutation({
 *   variables: {
 *      participantObject: // value for 'participantObject'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useAddParticipantToRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantToRoomMutation, AddParticipantToRoomMutationVariables>) {
        return Apollo.useMutation<AddParticipantToRoomMutation, AddParticipantToRoomMutationVariables>(AddParticipantToRoomDocument, baseOptions);
      }
export type AddParticipantToRoomMutationHookResult = ReturnType<typeof useAddParticipantToRoomMutation>;
export type AddParticipantToRoomMutationResult = Apollo.MutationResult<AddParticipantToRoomMutation>;
export type AddParticipantToRoomMutationOptions = Apollo.BaseMutationOptions<AddParticipantToRoomMutation, AddParticipantToRoomMutationVariables>;
export const UpdateRoomVotingStatusDocument = gql`
    mutation updateRoomVotingStatus($roomID: String!, $newVotingStatus: Boolean!) {
  updateRoomVotingStatus(roomID: $roomID, newVotingStatus: $newVotingStatus) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type UpdateRoomVotingStatusMutationFn = Apollo.MutationFunction<UpdateRoomVotingStatusMutation, UpdateRoomVotingStatusMutationVariables>;

/**
 * __useUpdateRoomVotingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRoomVotingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomVotingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomVotingStatusMutation, { data, loading, error }] = useUpdateRoomVotingStatusMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      newVotingStatus: // value for 'newVotingStatus'
 *   },
 * });
 */
export function useUpdateRoomVotingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomVotingStatusMutation, UpdateRoomVotingStatusMutationVariables>) {
        return Apollo.useMutation<UpdateRoomVotingStatusMutation, UpdateRoomVotingStatusMutationVariables>(UpdateRoomVotingStatusDocument, baseOptions);
      }
export type UpdateRoomVotingStatusMutationHookResult = ReturnType<typeof useUpdateRoomVotingStatusMutation>;
export type UpdateRoomVotingStatusMutationResult = Apollo.MutationResult<UpdateRoomVotingStatusMutation>;
export type UpdateRoomVotingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateRoomVotingStatusMutation, UpdateRoomVotingStatusMutationVariables>;
export const UpdateUserParticipationDocument = gql`
    mutation updateUserParticipation($userID: String!, $roomID: String!, $newState: Boolean!) {
  updateUserParticipation(userID: $userID, roomID: $roomID, newState: $newState) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      job
      choiceValues {
        value
        index
      }
      needBreak
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type UpdateUserParticipationMutationFn = Apollo.MutationFunction<UpdateUserParticipationMutation, UpdateUserParticipationMutationVariables>;

/**
 * __useUpdateUserParticipationMutation__
 *
 * To run a mutation, you first call `useUpdateUserParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserParticipationMutation, { data, loading, error }] = useUpdateUserParticipationMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      roomID: // value for 'roomID'
 *      newState: // value for 'newState'
 *   },
 * });
 */
export function useUpdateUserParticipationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserParticipationMutation, UpdateUserParticipationMutationVariables>) {
        return Apollo.useMutation<UpdateUserParticipationMutation, UpdateUserParticipationMutationVariables>(UpdateUserParticipationDocument, baseOptions);
      }
export type UpdateUserParticipationMutationHookResult = ReturnType<typeof useUpdateUserParticipationMutation>;
export type UpdateUserParticipationMutationResult = Apollo.MutationResult<UpdateUserParticipationMutation>;
export type UpdateUserParticipationMutationOptions = Apollo.BaseMutationOptions<UpdateUserParticipationMutation, UpdateUserParticipationMutationVariables>;
export const UpdateUserNeedBreakDocument = gql`
    mutation updateUserNeedBreak($roomID: String!, $userID: String!, $needBreakState: Boolean!) {
  updateUserNeedBreak(roomID: $roomID, userID: $userID, needBreakState: $needBreakState) {
    _id
    breakEnd
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      job
      choiceValues {
        value
        index
      }
      needBreak
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type UpdateUserNeedBreakMutationFn = Apollo.MutationFunction<UpdateUserNeedBreakMutation, UpdateUserNeedBreakMutationVariables>;

/**
 * __useUpdateUserNeedBreakMutation__
 *
 * To run a mutation, you first call `useUpdateUserNeedBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNeedBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNeedBreakMutation, { data, loading, error }] = useUpdateUserNeedBreakMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *      needBreakState: // value for 'needBreakState'
 *   },
 * });
 */
export function useUpdateUserNeedBreakMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNeedBreakMutation, UpdateUserNeedBreakMutationVariables>) {
        return Apollo.useMutation<UpdateUserNeedBreakMutation, UpdateUserNeedBreakMutationVariables>(UpdateUserNeedBreakDocument, baseOptions);
      }
export type UpdateUserNeedBreakMutationHookResult = ReturnType<typeof useUpdateUserNeedBreakMutation>;
export type UpdateUserNeedBreakMutationResult = Apollo.MutationResult<UpdateUserNeedBreakMutation>;
export type UpdateUserNeedBreakMutationOptions = Apollo.BaseMutationOptions<UpdateUserNeedBreakMutation, UpdateUserNeedBreakMutationVariables>;
export const UpdateUserChoiceDocument = gql`
    mutation updateUserChoice($roomID: String!, $userID: String!, $choiceValues: ChoiceValuesProps!) {
  updateUserChoice(roomID: $roomID, userID: $userID, choiceValues: $choiceValues) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      job
      choiceValues {
        value
        index
      }
      active
      needBreak
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type UpdateUserChoiceMutationFn = Apollo.MutationFunction<UpdateUserChoiceMutation, UpdateUserChoiceMutationVariables>;

/**
 * __useUpdateUserChoiceMutation__
 *
 * To run a mutation, you first call `useUpdateUserChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserChoiceMutation, { data, loading, error }] = useUpdateUserChoiceMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *      choiceValues: // value for 'choiceValues'
 *   },
 * });
 */
export function useUpdateUserChoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserChoiceMutation, UpdateUserChoiceMutationVariables>) {
        return Apollo.useMutation<UpdateUserChoiceMutation, UpdateUserChoiceMutationVariables>(UpdateUserChoiceDocument, baseOptions);
      }
export type UpdateUserChoiceMutationHookResult = ReturnType<typeof useUpdateUserChoiceMutation>;
export type UpdateUserChoiceMutationResult = Apollo.MutationResult<UpdateUserChoiceMutation>;
export type UpdateUserChoiceMutationOptions = Apollo.BaseMutationOptions<UpdateUserChoiceMutation, UpdateUserChoiceMutationVariables>;
export const ResetUserChoiceActiveAndDidVoteDocument = gql`
    mutation resetUserChoiceActiveAndDidVote($roomID: String!, $userID: String!) {
  resetUserChoiceActiveAndDidVote(roomID: $roomID, userID: $userID) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type ResetUserChoiceActiveAndDidVoteMutationFn = Apollo.MutationFunction<ResetUserChoiceActiveAndDidVoteMutation, ResetUserChoiceActiveAndDidVoteMutationVariables>;

/**
 * __useResetUserChoiceActiveAndDidVoteMutation__
 *
 * To run a mutation, you first call `useResetUserChoiceActiveAndDidVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserChoiceActiveAndDidVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserChoiceActiveAndDidVoteMutation, { data, loading, error }] = useResetUserChoiceActiveAndDidVoteMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useResetUserChoiceActiveAndDidVoteMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserChoiceActiveAndDidVoteMutation, ResetUserChoiceActiveAndDidVoteMutationVariables>) {
        return Apollo.useMutation<ResetUserChoiceActiveAndDidVoteMutation, ResetUserChoiceActiveAndDidVoteMutationVariables>(ResetUserChoiceActiveAndDidVoteDocument, baseOptions);
      }
export type ResetUserChoiceActiveAndDidVoteMutationHookResult = ReturnType<typeof useResetUserChoiceActiveAndDidVoteMutation>;
export type ResetUserChoiceActiveAndDidVoteMutationResult = Apollo.MutationResult<ResetUserChoiceActiveAndDidVoteMutation>;
export type ResetUserChoiceActiveAndDidVoteMutationOptions = Apollo.BaseMutationOptions<ResetUserChoiceActiveAndDidVoteMutation, ResetUserChoiceActiveAndDidVoteMutationVariables>;
export const ResetUserActiveAndDidVoteDocument = gql`
    mutation resetUserActiveAndDidVote($roomID: String!, $userID: String!) {
  resetUserActiveAndDidVote(roomID: $roomID, userID: $userID) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type ResetUserActiveAndDidVoteMutationFn = Apollo.MutationFunction<ResetUserActiveAndDidVoteMutation, ResetUserActiveAndDidVoteMutationVariables>;

/**
 * __useResetUserActiveAndDidVoteMutation__
 *
 * To run a mutation, you first call `useResetUserActiveAndDidVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserActiveAndDidVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserActiveAndDidVoteMutation, { data, loading, error }] = useResetUserActiveAndDidVoteMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useResetUserActiveAndDidVoteMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserActiveAndDidVoteMutation, ResetUserActiveAndDidVoteMutationVariables>) {
        return Apollo.useMutation<ResetUserActiveAndDidVoteMutation, ResetUserActiveAndDidVoteMutationVariables>(ResetUserActiveAndDidVoteDocument, baseOptions);
      }
export type ResetUserActiveAndDidVoteMutationHookResult = ReturnType<typeof useResetUserActiveAndDidVoteMutation>;
export type ResetUserActiveAndDidVoteMutationResult = Apollo.MutationResult<ResetUserActiveAndDidVoteMutation>;
export type ResetUserActiveAndDidVoteMutationOptions = Apollo.BaseMutationOptions<ResetUserActiveAndDidVoteMutation, ResetUserActiveAndDidVoteMutationVariables>;
export const UpdateRoomPresetDocument = gql`
    mutation updateRoomPreset($roomID: String!, $presetIndex: Int!) {
  updateRoomPreset(roomID: $roomID, presetIndex: $presetIndex) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type UpdateRoomPresetMutationFn = Apollo.MutationFunction<UpdateRoomPresetMutation, UpdateRoomPresetMutationVariables>;

/**
 * __useUpdateRoomPresetMutation__
 *
 * To run a mutation, you first call `useUpdateRoomPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomPresetMutation, { data, loading, error }] = useUpdateRoomPresetMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      presetIndex: // value for 'presetIndex'
 *   },
 * });
 */
export function useUpdateRoomPresetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomPresetMutation, UpdateRoomPresetMutationVariables>) {
        return Apollo.useMutation<UpdateRoomPresetMutation, UpdateRoomPresetMutationVariables>(UpdateRoomPresetDocument, baseOptions);
      }
export type UpdateRoomPresetMutationHookResult = ReturnType<typeof useUpdateRoomPresetMutation>;
export type UpdateRoomPresetMutationResult = Apollo.MutationResult<UpdateRoomPresetMutation>;
export type UpdateRoomPresetMutationOptions = Apollo.BaseMutationOptions<UpdateRoomPresetMutation, UpdateRoomPresetMutationVariables>;
export const RemoveUserFromRoomDocument = gql`
    mutation removeUserFromRoom($roomID: String!, $userID: String!) {
  removeUserFromRoom(roomID: $roomID, userID: $userID) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type RemoveUserFromRoomMutationFn = Apollo.MutationFunction<RemoveUserFromRoomMutation, RemoveUserFromRoomMutationVariables>;

/**
 * __useRemoveUserFromRoomMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromRoomMutation, { data, loading, error }] = useRemoveUserFromRoomMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useRemoveUserFromRoomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromRoomMutation, RemoveUserFromRoomMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromRoomMutation, RemoveUserFromRoomMutationVariables>(RemoveUserFromRoomDocument, baseOptions);
      }
export type RemoveUserFromRoomMutationHookResult = ReturnType<typeof useRemoveUserFromRoomMutation>;
export type RemoveUserFromRoomMutationResult = Apollo.MutationResult<RemoveUserFromRoomMutation>;
export type RemoveUserFromRoomMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromRoomMutation, RemoveUserFromRoomMutationVariables>;
export const ResetAllChoicesDocument = gql`
    mutation resetAllChoices($roomID: String!) {
  resetAllChoices(roomID: $roomID) {
    _id
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type ResetAllChoicesMutationFn = Apollo.MutationFunction<ResetAllChoicesMutation, ResetAllChoicesMutationVariables>;

/**
 * __useResetAllChoicesMutation__
 *
 * To run a mutation, you first call `useResetAllChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAllChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAllChoicesMutation, { data, loading, error }] = useResetAllChoicesMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useResetAllChoicesMutation(baseOptions?: Apollo.MutationHookOptions<ResetAllChoicesMutation, ResetAllChoicesMutationVariables>) {
        return Apollo.useMutation<ResetAllChoicesMutation, ResetAllChoicesMutationVariables>(ResetAllChoicesDocument, baseOptions);
      }
export type ResetAllChoicesMutationHookResult = ReturnType<typeof useResetAllChoicesMutation>;
export type ResetAllChoicesMutationResult = Apollo.MutationResult<ResetAllChoicesMutation>;
export type ResetAllChoicesMutationOptions = Apollo.BaseMutationOptions<ResetAllChoicesMutation, ResetAllChoicesMutationVariables>;
export const ResetAllNeedBreakDocument = gql`
    mutation resetAllNeedBreak($roomID: String!) {
  resetAllNeedBreak(roomID: $roomID) {
    _id
    breakEnd
    voteComplete
    availablePresets {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    selectedPreset
    preset {
      name
      cardValues {
        value
        index
      }
      presetIndex
    }
    participants {
      _id
      name
      needBreak
      job
      choiceValues {
        value
        index
      }
      active
      role
      didVote
      avatarIndex
    }
  }
}
    `;
export type ResetAllNeedBreakMutationFn = Apollo.MutationFunction<ResetAllNeedBreakMutation, ResetAllNeedBreakMutationVariables>;

/**
 * __useResetAllNeedBreakMutation__
 *
 * To run a mutation, you first call `useResetAllNeedBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAllNeedBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAllNeedBreakMutation, { data, loading, error }] = useResetAllNeedBreakMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useResetAllNeedBreakMutation(baseOptions?: Apollo.MutationHookOptions<ResetAllNeedBreakMutation, ResetAllNeedBreakMutationVariables>) {
        return Apollo.useMutation<ResetAllNeedBreakMutation, ResetAllNeedBreakMutationVariables>(ResetAllNeedBreakDocument, baseOptions);
      }
export type ResetAllNeedBreakMutationHookResult = ReturnType<typeof useResetAllNeedBreakMutation>;
export type ResetAllNeedBreakMutationResult = Apollo.MutationResult<ResetAllNeedBreakMutation>;
export type ResetAllNeedBreakMutationOptions = Apollo.BaseMutationOptions<ResetAllNeedBreakMutation, ResetAllNeedBreakMutationVariables>;