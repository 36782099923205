import cn from 'classnames'
import React, { FC, useEffect } from 'react'

import { Separator } from 'common/components'
import { Userblock, Userlist, Terms } from 'components'
import { useAppState } from 'contexts/AppProvider'
import { useBreakpoints } from 'contexts/BreakpointProvider'

import styles from './Sidebar.module.scss'

export const Sidebar: FC = () => {
  const { isScreenMD } = useBreakpoints()
  const { isSidebarCollapsed, collapseSidebar } = useAppState()

  useEffect(() => {
    if (isScreenMD.down) {
      collapseSidebar(true)
    } else {
      collapseSidebar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenMD.down])

  return (
    <div
      className={cn(
        styles.sidebarContainer,
        { [styles.sidebarPosition]: isScreenMD.down },
        { [styles.sidebarClosed]: isSidebarCollapsed }
      )}
    >
      <Userblock />
      <Separator />
      <Userlist />
      <Terms />
    </div>
  )
}
