import cn from 'classnames'
import { motion } from 'framer-motion'
import React, { FC, useEffect, useState } from 'react'
import { CgClose as StopBreak } from 'react-icons/cg'

import { Button } from 'common/components/Button'
import { useParticipant, useRoom } from 'contexts/RoomProvider'
import { useResetAllNeedBreakMutation } from 'graphql/interfaces/schema'

import styles from './BreakScreen.module.scss'

const ONE_SEC_IN_MS = 1000

const getNumberOrNaN = (value: string | null | undefined): number => parseInt(String(value), 10)
const isValidNumber = (number: number): boolean => !Number.isNaN(number)

export const BreakScreen: FC = () => {
  const { participant } = useParticipant()
  const { room, clearAppState } = useRoom()
  const [resetAllNeedBreak] = useResetAllNeedBreakMutation()
  const isHost: boolean = participant?.role === 'host'
  const [diff, setDiff] = useState<number | null>(null)
  const [breakEndUnix, setBreakEndUnix] = useState<number | null>(null)
  const [timeOut, setTimeOut] = useState<ReturnType<typeof setInterval> | null>(null)
  const isTimeOver = diff && diff < 0

  useEffect(() => {
    if (room) {
      // entry point to handle start countdown
      const breakEnd = getNumberOrNaN(room.breakEnd)
      if (isValidNumber(breakEnd)) {
        setBreakEndUnix(breakEnd)
      } else {
        setBreakEndUnix(null)
        setDiff(null)
      }
    }
  }, [room])

  useEffect(() => {
    // start end set interval if breakEnd value exists (see useEffect above)
    const interval = breakEndUnix
      ? setInterval(() => {
          const currentUnixDate = new Date().getTime()
          setDiff(breakEndUnix - currentUnixDate)
        }, ONE_SEC_IN_MS)
      : null

    setTimeOut(interval)

    return () => {
      if (interval) {
        // clear interval on page reload
        clearInterval(interval)
      }
    }
  }, [breakEndUnix])

  useEffect(() => {
    // clear interval if time is over or breakEnd value does not exist
    if (timeOut && (isTimeOver || !breakEndUnix)) {
      clearInterval(timeOut)
      setTimeOut(null)
    }
  }, [breakEndUnix, timeOut, isTimeOver])

  return (
    <>
      {breakEndUnix && diff && (
        <motion.div className={styles.componentContainer} animate={{ opacity: [0, 0.9] }} transition={{ duration: 1.3 }}>
          <div className={styles.breakScreen}>
            {isTimeOver ? (
              <div style={{ color: '#fff' }}>{isHost ? 'Press continue...' : 'Waiting for host...'}</div>
            ) : (
              <div className={styles.countdown}>{`${new Date(diff).getMinutes()} : ${new Date(diff)
                .getSeconds()
                .toString()
                .padStart(2, '0')}`}</div>
            )}
            <div className={styles.breakHeadlineContainer}>
              <div className={styles.breakHeadline}>Grab yourself a warming cup of coffee</div>
              <div className={styles.stage}>
                <div className={styles.dotFlashing} />
              </div>
            </div>
            {isHost && (
              <Button
                className={cn(styles.endBreakBtn, { [styles.continue]: isTimeOver })}
                onClick={() => resetAllNeedBreak({ variables: { roomID: room!._id } }).catch(clearAppState)}
                iconRight={<StopBreak />}
              >
                {isTimeOver ? 'Continue' : 'End break'}
              </Button>
            )}
          </div>
        </motion.div>
      )}
    </>
  )
}
