/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ParticipantProps, Room, useRoomQuery, useUpdateUserNeedBreakMutation } from 'graphql/interfaces/schema'
import { ROOM_UPDATED } from 'querys'
import { noOp } from 'utils/noOp'

interface RoomContextProps {
  room: Room | undefined
  roomID: string
  setRoomID: (props: string) => void
  loading: boolean
  collapseSidebar: boolean
  setCollapseSidebar: (props: boolean) => void
  setLoading: (props: boolean) => void
  clearAppState: () => void
  gotoRoom: (newRoomID: string, participantID: string) => void
}

const RoomContext = createContext<RoomContextProps>({
  room: undefined,
  roomID: '',
  setRoomID: noOp,
  loading: true,
  collapseSidebar: false,
  setCollapseSidebar: noOp,
  clearAppState: noOp,
  setLoading: noOp,
  gotoRoom: noOp,
})

const LOCAL_STORAGE_PARTICIPANT_ID = 'participant_id'
const LOCAL_STORAGE_ROOM_ID = 'room_id'

const RoomProvider: FC = ({ children }) => {
  const [roomID, setRoomID] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [collapseSidebar, setCollapseSidebar] = useState<boolean>(false)
  const history = useHistory()

  const { data: roomData, subscribeToMore: roomSubscription } = useRoomQuery({
    variables: { roomID },
    skip: !roomID,
    onCompleted: () => setLoading(false),
  })

  const clearAppState = (): void => {
    localStorage.clear()
    setRoomID('')
    history.push('/')
  }

  const gotoRoom = (newRoomID: string, participantID: string): void => {
    localStorage.setItem(LOCAL_STORAGE_ROOM_ID, newRoomID)
    localStorage.setItem(LOCAL_STORAGE_PARTICIPANT_ID, participantID)
    setLoading(true)
    history.push(`/room/${newRoomID}`)
  }

  useEffect(() => {
    if (roomData && roomSubscription) {
      roomSubscription({
        document: ROOM_UPDATED,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          const newFeedItem = subscriptionData.data.room

          return {
            ...prev,
            room: newFeedItem,
          }
        },
      })
    }
  }, [roomData, roomSubscription])

  const value = {
    room: roomData?.room,
    roomID,
    setRoomID,
    gotoRoom,
    collapseSidebar,
    setCollapseSidebar,
    clearAppState,
    loading,
    setLoading,
  }

  return <RoomContext.Provider value={value}>{children}</RoomContext.Provider>
}

export const useRoom = (): RoomContextProps => useContext(RoomContext)

export const useParticipant = (): {
  participant: ParticipantProps | undefined
  needBreak: boolean
  takeBreak: () => void
} => {
  const [updateUserNeedBreak] = useUpdateUserNeedBreakMutation()
  const { room, roomID, setLoading, clearAppState } = useRoom()
  const [needBreak, setNeedBreak] = useState<boolean>(false)
  const storageParticipantID = localStorage.getItem(LOCAL_STORAGE_PARTICIPANT_ID)
  const participant = room?.participants.find(user => user._id === storageParticipantID)

  const takeBreak = (): void => {
    if (room && participant) {
      updateUserNeedBreak({
        variables: { roomID: room?._id, userID: participant?._id, needBreakState: !participant.needBreak },
      }).catch(clearAppState)
    }
  }

  useEffect(() => {
    if (!roomID && participant) {
      setLoading(false)
    }
    participant && setNeedBreak(participant?.needBreak)
  }, [roomID, participant])

  return { participant, needBreak, takeBreak }
}

export default RoomProvider
