// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingPage_isLoadingContainer__13Ncn {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh; }\n  .LoadingPage_isLoadingContainer__13Ncn .LoadingPage_textStyle__33ycr {\n    font-size: 15pt; }\n", ""]);
// Exports
exports.locals = {
	"isLoadingContainer": "LoadingPage_isLoadingContainer__13Ncn",
	"textStyle": "LoadingPage_textStyle__33ycr"
};
module.exports = exports;
