import React, { FC } from 'react'

import styles from './Terms.module.scss'

export const Terms: FC = () => (
  <div className={styles.basicTermsStyle}>
    <a className={styles.link} href="https://scrumdenken.de/impressum#system-message-container" rel="noreferrer" target="_blank">
      Impressum
    </a>
    <a
      className={styles.link}
      href="https://scrumdenken.de/datenschutz#system-message-container"
      rel="noreferrer"
      target="_blank"
    >
      Datenschutz
    </a>
  </div>
)
