import classNames from 'classnames'
import React, { FC } from 'react'

import styles from './Avatar.module.scss'
import { AvatarProps } from './Interfaces/AvatarProps'

export const Avatar: FC<AvatarProps> = ({ noOutline, avatarFile, className }) => (
  <div className={classNames(styles.avatarContainer, { [styles.noOutline]: noOutline }, className)}>
    <img src={avatarFile} alt={avatarFile} />
  </div>
)
