import React, { FC } from 'react'

import styles from './Separator.module.scss'

interface SeparatorProps {
  label?: string
  margin?: number
}

export const Separator: FC<SeparatorProps> = ({ label, margin }) => (
  <div className={styles.separatorContainer} style={{ margin: `${margin}px 0` }}>
    {label && <div className={styles.label}>{label}</div>}
    <div className={styles.seperator} />
  </div>
)
