import { Input as InputComp, InputProps } from '@grapes-agency/ui'
import React, { FC } from 'react'

import styles from './Input.module.scss'

export type { InputProps }

export const Input: FC<InputProps> = ({ className, ...props }) => (
  <InputComp className={`${styles.input} ${className || ''}`} {...props} />
)
