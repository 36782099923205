// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Terms_basicTermsStyle__2kS_a {\n  display: flex;\n  padding-bottom: 0.9375rem; }\n\n.Terms_link__3OleL {\n  display: flex;\n  flex-grow: 1;\n  justify-content: center;\n  color: #999;\n  text-decoration: none; }\n", ""]);
// Exports
exports.locals = {
	"basicTermsStyle": "Terms_basicTermsStyle__2kS_a",
	"link": "Terms_link__3OleL"
};
module.exports = exports;
