export const copyToClipboard = (text: string): Boolean => {
  const el = document.createElement('textarea')
  el.value = text
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'

  document.body.appendChild(el)
  el.select()
  const copyResult = document.execCommand('copy')
  document.body.removeChild(el)

  return copyResult
}
