import React, { createContext, FC, useContext, useEffect, useState } from 'react'

import { ParticipantProps, useResetAllNeedBreakMutation } from 'graphql/interfaces/schema'
import { noOp } from 'utils/noOp'

import { useParticipant, useRoom } from './RoomProvider'

interface AppContextProps {
  isSidebarCollapsed: boolean
  collapseSidebar: (state: boolean) => void
  toggleSidebar: () => void
  isBreakActive: boolean
  breakCountdown: string
  resetAllNeedBreak: () => void
  endBreak: () => void
}

const AppContext = createContext<AppContextProps>({
  isSidebarCollapsed: false,
  collapseSidebar: noOp,
  toggleSidebar: noOp,
  isBreakActive: false,
  breakCountdown: '',
  resetAllNeedBreak: noOp,
  endBreak: noOp,
})

const AppProvider: FC = ({ children }) => {
  const DEFAULT_BREAK_TIME = 300
  const { room, clearAppState } = useRoom()
  const { participant } = useParticipant()
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false)
  const [breakCountdownState, setBreakCountdownState] = useState<number>(DEFAULT_BREAK_TIME)
  const [isBreakActive, setIsBreakActive] = useState<boolean>(false)
  const [transformedCountdown, setTransformedCountdown] = useState<string>('')
  const [resetAllNeedBreak] = useResetAllNeedBreakMutation()
  const activeParticipantsInRoom = room?.participants.filter((element: ParticipantProps) => element.active)
  const participantsWhoNeedBreak = activeParticipantsInRoom?.filter(currentParticipant => currentParticipant.needBreak)
  const SESSION_STORAGE_COUNTDOWN = sessionStorage.getItem('breakCountdown')
  let timeout: NodeJS.Timeout

  const didEveryActiveUserVotedBreak =
    !!activeParticipantsInRoom &&
    activeParticipantsInRoom?.length > 1 &&
    participantsWhoNeedBreak?.length === activeParticipantsInRoom?.length

  useEffect(() => {
    setTransformedCountdown(transformToMinutesAndSeconds(breakCountdownState))
  }, [breakCountdownState])

  useEffect(() => {
    if (didEveryActiveUserVotedBreak) {
      setIsBreakActive(true)
      sessionStorage.setItem('breakCountdown', JSON.stringify(breakCountdownState))
    } else {
      setIsBreakActive(false)
      sessionStorage.removeItem('breakCountdown')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, activeParticipantsInRoom, isBreakActive, participantsWhoNeedBreak])

  useEffect(() => {
    if (SESSION_STORAGE_COUNTDOWN) {
      setBreakCountdownState(JSON.parse(SESSION_STORAGE_COUNTDOWN))
    }

    if (!participant?.needBreak) {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SESSION_STORAGE_COUNTDOWN, participant?.needBreak])

  const handleEndBreak = (): void => {
    room?._id && resetAllNeedBreak({ variables: { roomID: room?._id } }).catch(clearAppState)
    setBreakCountdownState(DEFAULT_BREAK_TIME)
    clearTimeout(timeout)
  }

  const transformToMinutesAndSeconds = (count: number): string => {
    const minutes = Math.floor(count / 60).toFixed(0)
    const seconds = count % 60
    return `${minutes} : ${`${seconds <= 9 ? 0 : ''}${seconds}`}`
  }

  const toggleSidebar = (): void => {
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  const collapseSidebar = (state: boolean): void => {
    setIsSidebarCollapsed(state)
  }

  const value = {
    isSidebarCollapsed,
    collapseSidebar,
    toggleSidebar,
    isBreakActive,
    breakCountdown: transformedCountdown,
    resetAllNeedBreak,
    endBreak: handleEndBreak,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppState = (): AppContextProps => useContext(AppContext)

export default AppProvider
