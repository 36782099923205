// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable property-no-vendor-prefix */\n/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.VotingCard_btnBasic__3SKza {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 2.8125rem;\n  height: 2.8125rem;\n  margin: 0.1875rem;\n  padding: 0 0.625rem;\n  font-size: 18px;\n  font-family: \"Montserrat\", sans-serif;\n  border: 1px solid #dedede;\n  border-radius: 0.25rem;\n  outline: none;\n  cursor: pointer;\n  transition: transform 0.1s; }\n  .VotingCard_btnBasic__3SKza:hover {\n    transform: scale(1.1); }\n\n.VotingCard_active__3-X05 {\n  border: 2px solid #63c0ae !important;\n  border-radius: 10px; }\n", ""]);
// Exports
exports.locals = {
	"btnBasic": "VotingCard_btnBasic__3SKza",
	"active": "VotingCard_active__3-X05"
};
module.exports = exports;
