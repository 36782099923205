import cn from 'classnames'
import { motion } from 'framer-motion'
import React, { FC } from 'react'

import { ThinkBubble } from 'components/ThinkBubble'

import styles from './Userinfo.module.scss'

interface UserinfoProps {
  name: string
  avatarVariant?: number
  voted?: boolean
  className?: string
  title?: string
  isTabletOrMobile?: boolean
  needBreak?: boolean
  customSizeClass?: string
}

export const Userinfo: FC<UserinfoProps> = ({
  name,
  avatarVariant,
  voted = false,
  className,
  isTabletOrMobile = false,
  needBreak,
  customSizeClass,
  title,
}) => (
  <div className={cn(styles.userinfo, className)}>
    <div
      className={cn(
        styles.avatar,
        customSizeClass,
        { [styles.tabletAndMobileAvatarSize]: isTabletOrMobile },
        { [styles.voted]: voted }
      )}
      style={{ backgroundImage: `url(/images/avatar/avatar-${avatarVariant}.jpg)` }}
      title={title}
    >
      {needBreak && (
        <motion.div className={styles.bubbles} animate={{ opacity: [0, 1], y: [10, 0] }} transition={{ duration: 0.5 }}>
          <ThinkBubble />
        </motion.div>
      )}
    </div>
    <div className={styles.details}>{name && <div className={styles.name}>{name}</div>}</div>
  </div>
)
