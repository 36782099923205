import { ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import React, { FC } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import AppProvider from 'contexts/AppProvider'
import MediaProvider from 'contexts/BreakpointProvider'
import RoomProvider from 'contexts/RoomProvider'
import { Board } from 'pages/Board/Board'
import { Login } from 'pages/Login/Login'

import './global.scss'

window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_API_URL || 'ws://localhost:4000/graphql',
  options: {
    reconnect: true,
  },
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql',
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVER_URL || 'http://localhost:4000/',
  cache: new InMemoryCache(),
  link: splitLink,
})

const App: FC = () => (
  <ApolloProvider client={client}>
    <Router>
      <RoomProvider>
        <AppProvider>
          <MediaProvider>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="/room/:id">
                <Board />
              </Route>
            </Switch>
          </MediaProvider>
        </AppProvider>
      </RoomProvider>
    </Router>
  </ApolloProvider>
)

render(<App />, document.getElementById('app'))
