import React, { FC } from 'react'
import { CgCoffee as CoffeIcon } from 'react-icons/cg'
import { FcCheckmark as VotedIcon } from 'react-icons/fc'
import { RiVipCrownLine as ModeratorCrown, RiDeleteBack2Fill as RemoveIcon } from 'react-icons/ri'

import { useParticipant, useRoom } from 'contexts/RoomProvider'
import { ParticipantProps, useRemoveUserFromRoomMutation } from 'graphql/interfaces/schema'

import styles from './UserTable.module.scss'

interface UserTableProps {
  headline?: string
  users: Array<ParticipantProps>
}

export const UserTable: FC<UserTableProps> = ({ headline, users }) => {
  const [removeUserFromRoom] = useRemoveUserFromRoomMutation()
  const { room } = useRoom()
  const { participant } = useParticipant()

  const onClickRemoveUser = async (userId: string): Promise<void> => {
    if (room) {
      await removeUserFromRoom({ variables: { roomID: room?._id, userID: userId } })
        // eslint-disable-next-line no-console
        .catch(e => console.log(e))
    }
  }

  return (
    <>
      {headline && <div className={styles.heading}>{headline}</div>}
      <table className={styles.table}>
        <tbody>
          {users.map(({ name, needBreak, didVote, role, _id }, index) => (
            <tr className={styles.tableRow} key={index}>
              <td className={styles.name}>{name}</td>
              <td className={styles.statusIcon}>{needBreak && <CoffeIcon title="Coffee break" />}</td>
              <td className={styles.statusIcon}>{role === 'host' && <ModeratorCrown title="Moderator" />}</td>
              <td className={styles.statusIcon}>{didVote && <VotedIcon title="User voted" />}</td>
              <td className={styles.statusIcon}>
                {participant?.role === 'host' && role !== 'host' && (
                  <RemoveIcon
                    style={{ cursor: 'pointer' }}
                    color="#E57373"
                    title="remove user"
                    onClick={() => onClickRemoveUser(_id)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
