/* eslint-disable @typescript-eslint/no-explicit-any */
export const fullWidthStyle = {
  option: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),
  container: (provided: any) => ({
    ...provided,
    width: '220px',
    border: '1px solid #238173',
    borderRadius: '10px',
    cursor: 'pointer',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#238173',
    border: 'none',
    borderRadius: '10px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    svg: {
      fill: '#238173',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#238173',
    fontWeight: 500,
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'transparent',
  }),
}

export const lowWidthStyle = {
  option: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),
  container: (provided: any) => ({
    ...provided,
    width: '130px',
    height: '40px',
    border: '1px solid #238173',
    borderRadius: '10px',
    cursor: 'pointer',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#36454f',
    border: 'none',
    borderRadius: '10px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
      color: '#238173',
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    width: '300px',
    display: 'flex',
    justifyContent: 'center',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    svg: {
      fill: '#238173',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#238173',
    fontWeight: 500,
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'transparent',
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100px',
  }),
}
