import { Spinner } from '@grapes-agency/ui'
import React, { FC } from 'react'

import styles from './LoadingPage.module.scss'

export const LoadingPage: FC = () => (
  <div className={styles.isLoadingContainer}>
    <div className={styles.textStyle}>Page is Loading...</div>
    <br />
    <div>
      <Spinner size="large" />
    </div>
  </div>
)
