/* eslint-disable no-restricted-globals */
import { Button } from '@grapes-agency/ui'
import cn from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { AiOutlineEye as RevealIcon } from 'react-icons/ai'
import { GrPowerReset as ResetIcon } from 'react-icons/gr'
import Select, { ValueType } from 'react-select'

import { lowWidthStyle, fullWidthStyle } from 'components/HostActions/CustomStyles'
import { ReactSelectValue } from 'components/HostActions/Interfaces/HostActionProps'
import { useBreakpoints } from 'contexts/BreakpointProvider'
import { useParticipant, useRoom } from 'contexts/RoomProvider'
import {
  useResetAllChoicesMutation,
  useUpdateRoomPresetMutation,
  useUpdateRoomVotingStatusMutation,
} from 'graphql/interfaces/schema'
import { isReactSelectFormat } from 'utils/isReactSelectFormat'

import styles from './HostActions.module.scss'

export const HostActions: FC = () => {
  const { room, clearAppState } = useRoom()
  const { participant } = useParticipant()
  const BUTTON_REVEAL_TEXT = `Reveal voting`
  const BUTTON_RESET_TEXT = `Reset voting`
  const [updateRoomVotingStatus] = useUpdateRoomVotingStatusMutation()
  const [resetAllChoices] = useResetAllChoicesMutation()
  const [updateRoomPreset] = useUpdateRoomPresetMutation()
  const [options, setOptions] = useState<Array<ReactSelectValue>>([])
  const { isScreenSM } = useBreakpoints()
  const [buttonTextState] = useState<{ reveal: string; reset: string }>({
    reveal: BUTTON_REVEAL_TEXT,
    reset: BUTTON_RESET_TEXT,
  })

  const handleChange = (value: ValueType<ReactSelectValue>): void => {
    if (participant && isReactSelectFormat(value)) {
      if (room) {
        updateRoomPreset({
          variables: { roomID: room?._id, presetIndex: value.presetIndex },
        }).catch(clearAppState)
      }
    }
  }

  const resetVoting = async (): Promise<void> => {
    room && resetAllChoices({ variables: { roomID: room._id } }).catch(clearAppState)
  }

  useEffect(() => {
    if (room) {
      setOptions(
        room.availablePresets.map(iteratorPreset => ({
          label: iteratorPreset.name,
          value: iteratorPreset.name,
          presetIndex: iteratorPreset.presetIndex,
        }))
      )
    }
  }, [room])

  return (
    <div className={styles.hostActionsContainer}>
      {participant?.role === 'host' && (
        <>
          <Button
            title="Reveal: Flips all cards immediately"
            iconRight={<RevealIcon />}
            className={cn(styles.btnBasic, { [styles.btnsIsScreenSMandBelow]: !isScreenSM.up })}
            onClick={() =>
              room && updateRoomVotingStatus({ variables: { roomID: room._id, newVotingStatus: true } }).catch(clearAppState)
            }
          >
            {isScreenSM.up && buttonTextState.reveal}
          </Button>
          <Button
            title="Reset: cancels all votings, start new round"
            iconRight={<ResetIcon />}
            className={cn(styles.btnBasic, { [styles.btnsIsScreenSMandBelow]: !isScreenSM.up })}
            onClick={() => resetVoting()}
          >
            {isScreenSM.up && buttonTextState.reset}
          </Button>
        </>
      )}
      <div className={styles.selectContainer}>
        {participant?.role === 'host' && options.length > 0 && (
          <>
            <Select
              className={styles.selectStyles}
              styles={isScreenSM.up ? fullWidthStyle : lowWidthStyle}
              options={options}
              menuPlacement="top"
              hideSelectedOptions={true}
              onChange={handleChange}
              defaultValue={options.find(option => option.presetIndex === room?.selectedPreset)}
            />
          </>
        )}
      </div>
    </div>
  )
}
