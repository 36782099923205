// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Userinfo_userinfo__19GHA {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n  .Userinfo_userinfo__19GHA .Userinfo_details__FTXZM {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 0.625rem 0; }\n    .Userinfo_userinfo__19GHA .Userinfo_details__FTXZM .Userinfo_name__3qnOP {\n      max-width: 175px;\n      color: #36454f;\n      font-weight: 300;\n      font-size: 1rem;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden; }\n  .Userinfo_userinfo__19GHA .Userinfo_avatar__10VZp {\n    z-index: 20;\n    width: 70px;\n    height: 70px;\n    background-size: contain;\n    border: 4px solid #dedede;\n    border-radius: 50%; }\n    .Userinfo_userinfo__19GHA .Userinfo_avatar__10VZp .Userinfo_bubbles__35MMP {\n      position: relative;\n      right: 75%;\n      bottom: 45%; }\n  .Userinfo_userinfo__19GHA .Userinfo_tabletAndMobileAvatarSize__3SIac {\n    width: 46.2px;\n    height: 46.2px; }\n\n.Userinfo_voted__1lXME {\n  border: 4px solid #00a1ff !important; }\n", ""]);
// Exports
exports.locals = {
	"userinfo": "Userinfo_userinfo__19GHA",
	"details": "Userinfo_details__FTXZM",
	"name": "Userinfo_name__3qnOP",
	"avatar": "Userinfo_avatar__10VZp",
	"bubbles": "Userinfo_bubbles__35MMP",
	"tabletAndMobileAvatarSize": "Userinfo_tabletAndMobileAvatarSize__3SIac",
	"voted": "Userinfo_voted__1lXME"
};
module.exports = exports;
