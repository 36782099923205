// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.HostActions_hostActionsContainer__1yxth {\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  width: 100%;\n  max-width: 100%;\n  padding: 0.3125rem; }\n  .HostActions_hostActionsContainer__1yxth .HostActions_selectContainer__3LVqs {\n    display: flex;\n    align-content: center;\n    justify-content: center;\n    width: -moz-fit-content;\n    width: fit-content;\n    margin: 0 5px;\n    font-family: \"Montserrat\", sans-serif; }\n    .HostActions_hostActionsContainer__1yxth .HostActions_selectContainer__3LVqs .HostActions_dropdownIcon__rdgtB {\n      width: 20px;\n      height: 20px; }\n  .HostActions_hostActionsContainer__1yxth .HostActions_btnBasic__3yK7q {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0 5px;\n    padding: 0.625rem 1.875rem;\n    color: #fff;\n    font-weight: 400;\n    font-size: 1rem;\n    font-family: \"Montserrat\", sans-serif;\n    background: #238173;\n    border-radius: 10px; }\n    .HostActions_hostActionsContainer__1yxth .HostActions_btnBasic__3yK7q span {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: center;\n      width: -moz-fit-content;\n      width: fit-content; }\n    .HostActions_hostActionsContainer__1yxth .HostActions_btnBasic__3yK7q:focus {\n      background: #238173; }\n    .HostActions_hostActionsContainer__1yxth .HostActions_btnBasic__3yK7q:hover {\n      background: #238173; }\n    .HostActions_hostActionsContainer__1yxth .HostActions_btnBasic__3yK7q svg {\n      color: #fff; }\n      .HostActions_hostActionsContainer__1yxth .HostActions_btnBasic__3yK7q svg path {\n        stroke: #fff; }\n  .HostActions_hostActionsContainer__1yxth .HostActions_btnsIsScreenSMandBelow__35QeB {\n    width: 40px;\n    height: 30px;\n    padding: 0; }\n", ""]);
// Exports
exports.locals = {
	"hostActionsContainer": "HostActions_hostActionsContainer__1yxth",
	"selectContainer": "HostActions_selectContainer__3LVqs",
	"dropdownIcon": "HostActions_dropdownIcon__rdgtB",
	"btnBasic": "HostActions_btnBasic__3yK7q",
	"btnsIsScreenSMandBelow": "HostActions_btnsIsScreenSMandBelow__35QeB"
};
module.exports = exports;
