import cn from 'classnames'
import React, { FC } from 'react'

import styles from './ThinkBubble.module.scss'

export const ThinkBubble: FC = () => (
  <div className={styles.componentContainer}>
    <div className={styles.row}>
      <div className={styles.mainBubble}>
        <div className={styles.innerText}>...coffee break?</div>
      </div>
    </div>
    <div className={cn(styles.row, styles.innerBubbleContainer)}>
      <div className={styles.pseudoSubBubble} />
      <div className={styles.innerBubble} />
    </div>
    <div className={cn(styles.row, styles.outterBubbleContainer)}>
      <div className={styles.outterBubble} />
    </div>
  </div>
)
