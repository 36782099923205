// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Avatar_avatarContainer__213uz,\n.Avatar_noOutline__3VuzX {\n  box-sizing: border-box;\n  width: 6.875rem;\n  height: 6.875rem;\n  padding: 0.625rem;\n  border: 1px solid #d6d6d6;\n  border-radius: 3.4375rem;\n  overflow: hidden; }\n  .Avatar_avatarContainer__213uz img,\n  .Avatar_noOutline__3VuzX img {\n    width: 5.5rem;\n    height: 5.5rem;\n    border-radius: 3.4375rem;\n    object-fit: cover; }\n\n.Avatar_noOutline__3VuzX {\n  border: 0 solid transparent; }\n", ""]);
// Exports
exports.locals = {
	"avatarContainer": "Avatar_avatarContainer__213uz",
	"noOutline": "Avatar_noOutline__3VuzX"
};
module.exports = exports;
