import React, { FC, useRef, useState } from 'react'

import { TabProps } from './Tab'

import './Tabmenu.scss'

export interface ClassesOptions {
  tabmenu?: string
  tab?: string
  tabActive?: string
  content?: string
}

export interface TabmenuProps {
  children: Array<React.ReactElement<TabProps>>
  className?: string
  classes?: ClassesOptions
  activeTabIndex?: number
}

export const Tabmenu: FC<TabmenuProps> = ({ children, classes, activeTabIndex }) => {
  const [activeTab, setTab] = useState<number>(activeTabIndex || 0)
  const tabRef = useRef<HTMLInputElement>(null)

  const selectTab = (tabIndex: number): void => {
    if (tabIndex !== activeTab) {
      setTab(tabIndex)
    }
  }

  const handleKeyDown = (ev: React.KeyboardEvent): void => {
    if (ev.key === 'enter') {
      tabRef.current!.focus()
    }
  }

  return (
    <>
      <div className={`tabmenu--tabs ${classes?.tabmenu ? classes.tabmenu : ''}`}>
        {/* tabs */}
        {children.map((tab, i) => (
          <div
            ref={tabRef}
            role="tab"
            onClick={() => selectTab(i)}
            tabIndex={i}
            onKeyDown={handleKeyDown}
            key={i}
            className={`${activeTab === i ? `tab active ${classes?.tabActive ? classes?.tabActive : ''}` : 'tab'} ${
              classes?.tab ? classes?.tab : ''
            } ${tab.props.className ? tab.props.className : ''}`}
          >
            {tab.props.title}
          </div>
        ))}
      </div>
      <div className="tabmenu--content">
        {/* content */}
        {children.map((tab, i) => (
          <div
            key={i}
            className={`${activeTab === i ? `active ${classes?.content ? classes?.content : ''}` : 'hidden'} ${
              classes?.content ? classes?.content : ''
            }`}
          >
            {tab.props.children}
          </div>
        ))}
      </div>
    </>
  )
}
