/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQueries } from '@react-hook/media-query'
import React, { createContext, FC, useContext } from 'react'

interface MediaVariants {
  exact: boolean
  down: boolean
  up: boolean
}
interface BreakpointContextProps {
  isScreenXS: MediaVariants
  isScreenSM: MediaVariants
  isScreenMD: MediaVariants
  isScreenLG: MediaVariants
  isScreenXL: MediaVariants
}

// Breakpoints trigger at...
const BREAKPOINTS = {
  XS: 0,
  SM: 600,
  MD: 960,
  LG: 1280,
  XL: 1920,
}

const DEFAULT_VARIANT = {
  exact: false,
  down: false,
  up: false,
}

const BreakpointContext = createContext<BreakpointContextProps>({
  isScreenXS: DEFAULT_VARIANT,
  isScreenSM: DEFAULT_VARIANT,
  isScreenMD: DEFAULT_VARIANT,
  isScreenLG: DEFAULT_VARIANT,
  isScreenXL: DEFAULT_VARIANT,
})

const BreakpointProvider: FC = ({ children }) => {
  const { matches } = useMediaQueries({
    isScreenXSExact: `(min-width: ${BREAKPOINTS.XS}px) and (max-width: ${BREAKPOINTS.SM - 1}px)`,
    isScreenXSUp: `(min-width: ${BREAKPOINTS.XS}px)`,
    isScreenXSDown: `(max-width: ${BREAKPOINTS.SM - 1}px)`,

    isScreenSMExact: `(min-width: ${BREAKPOINTS.SM}px) and (max-width: ${BREAKPOINTS.MD - 1}px)`,
    isScreenSMUp: `(min-width: ${BREAKPOINTS.SM}px)`,
    isScreenSMDown: `(max-width: ${BREAKPOINTS.MD - 1}px)`,

    isScreenMDExact: `(min-width: ${BREAKPOINTS.MD}px) and (max-width: ${BREAKPOINTS.LG - 1}px)`,
    isScreenMDUp: `(min-width: ${BREAKPOINTS.MD}px)`,
    isScreenMDDown: `(max-width: ${BREAKPOINTS.LG - 1}px)`,

    isScreenLGExact: `(min-width: ${BREAKPOINTS.LG}px) and (max-width: ${BREAKPOINTS.XL - 1}px)`,
    isScreenLGUp: `(min-width: ${BREAKPOINTS.LG}px)`,
    isScreenLGDown: `(max-width: ${BREAKPOINTS.XL - 1}px)`,

    isScreenXLExact: `(min-width: ${BREAKPOINTS.XL}px)`,
    isScreenXLUp: `(min-width: ${BREAKPOINTS.XL}px)`,
    isScreenXLDown: `(max-width: ${BREAKPOINTS.XL - 1}px)`,
  })

  const value = {
    isScreenXS: {
      exact: matches.isScreenXSExact,
      down: matches.isScreenXSDown,
      up: matches.isScreenXSUp,
    },
    isScreenSM: {
      exact: matches.isScreenSMExact,
      down: matches.isScreenSMDown,
      up: matches.isScreenSMUp,
    },
    isScreenMD: {
      exact: matches.isScreenMDExact,
      down: matches.isScreenMDDown,
      up: matches.isScreenMDUp,
    },
    isScreenLG: {
      exact: matches.isScreenLGExact,
      down: matches.isScreenLGDown,
      up: matches.isScreenLGUp,
    },
    isScreenXL: {
      exact: matches.isScreenXLExact,
      down: matches.isScreenXLDown,
      up: matches.isScreenXLUp,
    },
  }

  return <BreakpointContext.Provider value={value}>{children}</BreakpointContext.Provider>
}

export const useBreakpoints = (): BreakpointContextProps => useContext(BreakpointContext)

export default BreakpointProvider
