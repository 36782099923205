// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Dock_dockContainer__20E-W {\n  z-index: 30;\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  width: 100%;\n  height: -moz-fit-content;\n  height: fit-content;\n  min-height: 100px;\n  font-family: \"Montserrat\", sans-serif; }\n  .Dock_dockContainer__20E-W .Dock_actions__1_voL {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 100%;\n    max-height: -moz-fit-content;\n    max-height: fit-content;\n    margin-right: 5px;\n    margin-left: 5px; }\n  .Dock_dockContainer__20E-W .Dock_rowDesktop__2_7Pu {\n    margin-bottom: 15px !important; }\n  .Dock_dockContainer__20E-W .Dock_row__1wxZ3 {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    max-width: 100%;\n    max-height: 100%;\n    margin-bottom: 0.625rem;\n    background: #fafafa;\n    border: 1px solid #dedede;\n    border-radius: 0.625rem; }\n  .Dock_dockContainer__20E-W .Dock_participantActions__2iBUL {\n    max-width: 100%;\n    max-height: 100%; }\n\n.Dock_stats__3KftR {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 10px;\n  color: #373737; }\n\n.Dock_median__1Xc_u {\n  padding: 0 5px;\n  font-size: 27px; }\n", ""]);
// Exports
exports.locals = {
	"dockContainer": "Dock_dockContainer__20E-W",
	"actions": "Dock_actions__1_voL",
	"rowDesktop": "Dock_rowDesktop__2_7Pu",
	"row": "Dock_row__1wxZ3",
	"participantActions": "Dock_participantActions__2iBUL",
	"stats": "Dock_stats__3KftR",
	"median": "Dock_median__1Xc_u"
};
module.exports = exports;
