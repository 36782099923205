// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Input_input__1G7Da {\n  background-color: #fafafa !important;\n  border-color: #dedede !important;\n  border-radius: 4px !important; }\n  .Input_input__1G7Da ::placeholder {\n    color: #e6e6e6; }\n\n.gui-input__inner {\n  min-height: 0.7rem;\n  padding: 10px;\n  color: #999;\n  font-size: 15px; }\n", ""]);
// Exports
exports.locals = {
	"input": "Input_input__1G7Da"
};
module.exports = exports;
