import { CardValuesProps } from 'graphql/interfaces/schema'

export const getMedianValue = (array: Array<CardValuesProps>): string => {
  // first filter index "0" (not valid result) and sort the array
  const sortedArray = array.filter(e => e.index !== 0).sort((a, b) => a.index - b.index)

  if (sortedArray && sortedArray.length <= 0) {
    return ''
  }

  // check if even or odd
  const even = sortedArray.length % 2 === 0

  // calc the median
  const median = Math.ceil(sortedArray.length / 2 + (even ? 1 : 0))

  // return the index of the array, -1 because array index starts with 0
  return sortedArray[median - 1].value
}
