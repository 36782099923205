import React from 'react'

interface emojiProps {
  role?: string
  ariaLabel?: string
  label: string
  ariaHidden?: boolean
  symbol: string
}

export const Emoji = (props: emojiProps): JSX.Element => (
  <span className="emoji" role="img" aria-label={props.label ? props.label : ''} aria-hidden={props.label ? 'false' : 'true'}>
    {props.symbol}
  </span>
)
