import { Button } from '@grapes-agency/ui'
import cn from 'classnames'
import React, { FC } from 'react'
import { CgCoffee as CoffeIcon } from 'react-icons/cg'
import { HiBan as AbstainIcon } from 'react-icons/hi'

import { VotingCard } from 'components'
import { useParticipant, useRoom } from 'contexts/RoomProvider'
import { CardValuesProps, useResetUserActiveAndDidVoteMutation, useUpdateUserChoiceMutation } from 'graphql/interfaces/schema'

import styles from './ParticipantActions.module.scss'

export const ParticipantActions: FC = () => {
  const { participant, takeBreak } = useParticipant()
  const { room, clearAppState } = useRoom()
  const [resetUserActiveAndDidVote] = useResetUserActiveAndDidVoteMutation()
  const [updateUserChoice] = useUpdateUserChoiceMutation()

  const handleVote = (pickedElement: CardValuesProps): void => {
    if (room) {
      if (participant) {
        updateUserChoice({
          variables: {
            roomID: room?._id,
            userID: participant._id,
            choiceValues: { value: pickedElement.value, index: pickedElement.index },
          },
        }).catch(clearAppState)
      }
    }
  }

  const returnFilteredPreset = (presetElement: CardValuesProps, index: number): JSX.Element => (
    <VotingCard
      className={cn({ [styles.noAnimate]: !participant?.active || room?.voteComplete })}
      key={index}
      style={{ backgroundPositionX: 40 * index }}
      onClick={() => handleVote(presetElement)}
      isActive={presetElement.value === participant?.choiceValues.value}
      isDisabled={!participant?.active || room?.voteComplete}
      value={presetElement.value}
    >
      <div className={styles.elementStyle}>{presetElement}</div>
    </VotingCard>
  )

  const toggleCheckbox = (): void => {
    if (room && participant) {
      resetUserActiveAndDidVote({ variables: { roomID: room._id, userID: participant._id } }).catch(clearAppState)
    }
  }

  return (
    <div className={styles.participantContainer}>
      <div className={styles.mappedCards}>
        {room?.preset.cardValues.map((element, index) => returnFilteredPreset(element, index))}
        <div className={styles.buttonContainer}>
          <Button className={cn(styles.btnBasic, styles.btnBreak)} onClick={() => takeBreak()} title="Request coffee break">
            <CoffeIcon className={styles.breakIcon} />
          </Button>

          <Button className={cn(styles.btnBasic, styles.btnAbstain)} onClick={() => toggleCheckbox()} title="I don´t vote">
            <AbstainIcon className={styles.abstainIcon} />
          </Button>
        </div>
      </div>
    </div>
  )
}
