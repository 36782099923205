// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Board_app__a_K8v {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%; }\n  .Board_app__a_K8v .Board_container__sGEUF {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%; }\n", ""]);
// Exports
exports.locals = {
	"app": "Board_app__a_K8v",
	"container": "Board_container__sGEUF"
};
module.exports = exports;
