import cn from 'classnames'
import React, { FC } from 'react'

import { HostActions } from 'components/HostActions/HostActions'
import { ParticipantActions } from 'components/ParticipantActions/ParticipantActions'
import { useBreakpoints } from 'contexts/BreakpointProvider'
import { useParticipant, useRoom } from 'contexts/RoomProvider'
import { ParticipantProps } from 'graphql/interfaces/schema'
import { getMedianValue } from 'utils/getMedianValue'

import styles from './Dock.module.scss'

export const Dock: FC = () => {
  const { room } = useRoom()
  const { participant } = useParticipant()
  const { isScreenSM, isScreenXS } = useBreakpoints()
  const participantsCount = room?.participants.filter((element: ParticipantProps) => element.active).length
  const participantsWithChoiceCount = room?.participants.filter((element: ParticipantProps) => !!element?.choiceValues.value)
    .length
  const votingResults = room?.participants.filter(p => p.active).map(p => p.choiceValues)
  const votingResultsAvaibale = votingResults && votingResults.length > 0
  const medianValue = votingResultsAvaibale && getMedianValue(votingResults!)

  return (
    <div className={cn(styles.dockContainer, { [styles.actionsTabletAndMobile]: isScreenXS.down })}>
      <div className={styles.actions}>
        {isScreenSM.up && (
          <div className={styles.stats}>
            {room?.voteComplete && <div className={styles.median}>{`Median: ${medianValue}`}</div>}
            <div className={styles.pendingVotes}>{` Votes ${participantsWithChoiceCount}/${participantsCount}`}</div>
          </div>
        )}
        {participant?.role === 'host' && (
          <div className={cn(styles.row, { [styles.rowDesktop]: isScreenSM.up })}>
            <HostActions />
          </div>
        )}
        <div className={cn(styles.row, styles.participantActions, { [styles.rowDesktop]: isScreenSM.up })}>
          <ParticipantActions />
        </div>
      </div>
    </div>
  )
}
