import cn from 'classnames'
import React, { FC } from 'react'

import styles from './ParticipantCard.module.scss'
import { Userinfo } from './components/Userinfo'

export interface CardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size: size
  isRevealed: boolean
  isActive?: boolean
  isDisabled?: boolean
  children?: React.ReactNode
  background?: boolean
  user?: {
    name: string
  }
  avatarIndex?: number
  value: string | number
  needBreak?: boolean
  slideUp?: boolean
  isLowestCard?: boolean
  isHighestCard?: boolean
  animate?: boolean
  voted?: boolean
}

export type size = 'mobile' | 'tablet' | 'desktop'

export const ParticipantCard: FC<CardProps> = props => {
  const {
    style,
    isActive,
    isRevealed,
    size,
    children,
    isDisabled,
    className,
    slideUp,
    value,
    user,
    needBreak,
    avatarIndex,
    isLowestCard = false,
    isHighestCard = false,
    background = false,
    animate = false,
    voted = false,
    ...other
  } = props
  return (
    <div className={cn(styles.cardContainer, { [styles.slideUp]: slideUp })}>
      <button
        className={cn(
          className,
          styles.flipCard,
          styles[size],
          styles.defaultStyle,
          { [styles.lowestGlow]: isLowestCard },
          { [styles.highestGlow]: isHighestCard },
          { [styles.revealed]: isRevealed },
          { [styles.animate]: animate },
          { [styles.active]: isActive },
          { [styles.disabled]: isDisabled }
        )}
        style={style}
        disabled={isDisabled}
        {...other}
      >
        <div className={cn(styles.flipCardInner)}>
          <div className={cn(styles.flipCardFront, { [styles.voted]: voted })}>
            <img src={`${process.env.PUBLIC_URL}/images/logo-w-small-quadrat.png`} className={styles.cardLogo} alt="S" />
          </div>
          <div className={styles.flipCardBack}>{value}</div>
        </div>
      </button>
      {user && (
        <Userinfo
          className={styles.cardUserInfo}
          voted={voted}
          needBreak={needBreak}
          isTabletOrMobile={!!(size === 'mobile' || size === 'tablet')}
          name={user.name}
          avatarVariant={avatarIndex}
        />
      )}
    </div>
  )
}
