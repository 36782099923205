import { Button as ButtonComp, ButtonProps } from '@grapes-agency/ui'
import React, { FC } from 'react'

import styles from './Button.module.scss'

export const Button: FC<ButtonProps> = ({ children, className, ...props }) => (
  <ButtonComp className={`${styles.btn} ${className || ''}`} {...props}>
    {children}
  </ButtonComp>
)
