import React, { FC } from 'react'

import { Userinfo } from 'components/ParticipantCard/components/Userinfo'
import { useParticipant } from 'contexts/RoomProvider'

import styles from './Userblock.module.scss'

export const Userblock: FC = () => {
  const { participant } = useParticipant()

  return (
    <>
      <div className={styles.userblockContainer}>
        {participant && (
          <Userinfo
            customSizeClass={styles.userblockAvatarSize}
            name={participant.name}
            avatarVariant={participant.avatarIndex}
            title={`That's you!`}
          />
        )}
      </div>
    </>
  )
}
