// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable scss/dollar-variable-pattern */\n/* stylelint-enable scss/dollar-variable-pattern */\n.Cards_cardsContainer__1N5JQ {\n  display: flex;\n  flex-grow: 1;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: space-evenly;\n  width: 100%;\n  margin-top: 15px;\n  font-family: \"Montserrat\", sans-serif;\n  outline: none;\n  overflow: auto; }\n", ""]);
// Exports
exports.locals = {
	"cardsContainer": "Cards_cardsContainer__1N5JQ"
};
module.exports = exports;
